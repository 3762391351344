import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/es/locale/ru_RU';
import kk_KZ from 'antd/es/locale/kk_KZ';

const antdLocales = {
  ru: ru_RU,
  kz: kk_KZ,
};

export const AppCustomization = ({children}) => {
  const { i18n: locale } = useTranslation();

  return <ConfigProvider locale={antdLocales[locale.language]}>{children}</ConfigProvider>;
};
