import React, { Component } from 'react';
import { YMaps, Map } from 'react-yandex-maps';

import { API_ROOT } from '../config';
import {
  Form,
  Input,
  Button,
  Radio,
  DatePicker,
  Row,
  Col,
  Checkbox,
  message,
  Select,
  Spin,
} from 'antd';
import Icon from '@ant-design/icons';
import BasicLayout from '../components/layout/BasicLayout';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getNetwork } from '../utils/getNetwork';

const { TextArea } = Input;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const Option = Select.Option;
class RoadRepairPage extends Component {
  form = React.createRef();
  constructor() {
    super();
    this.map = null;
    this.ymap = null;
    this.state = {
      center: [53.2775, 69.3883],
      zoom: 11,
      loading: false,
      roadsLoading: true,
      selectedItems: [],
      roads: [],
      redirect: false,
      repairProgress: '',
      repairProgressList: [],
      line: {},
      eventName: '',
      street: '',
      site: '',
      workCategory: '',
      streetIsBlocked: false,
      streetIsBlockedReason: '',
      streetIsBlockedDescription: '',
      roadwayIsOpened: false,
      roadwayIsOpenedReason: '',
      roadwayIsOpenedDescription: '',
      workplanStart: null,
      workplanEnd: null,
      isCross: false,
      comment: '',
      builder: '',
      objectType: 1,
      builderContacts: '',
      isIncompleteStartDate: false,
      isIncompleteEndDate: false,
      warrantyPeriod: '',
      coords: [],
      isHidden: false,
      categoryList: [],
      builderList: [],
      eventList: [],
      projectCost: '',
      amountPlanned: '',
      length: '',
      acceptedWorkPerson: '',
    };
    this.checkFunc = this.checkFunc.bind(this);
    this.changeVal = this.changeVal.bind(this);
    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
    this.getRoadById = this.getRoadById.bind(this);
    this.changeCheckbox = this.changeCheckbox.bind(this);
    this.getData = this.getData.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.changeMapObject = this.changeMapObject.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.getRepairProgresses = this.getRepairProgresses.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleBuilderChange = this.handleBuilderChange.bind(this);
    this.handleEventNameChange = this.handleEventNameChange.bind(this);
    this.handleRepairProgressChange = this.handleRepairProgressChange.bind(
      this
    );
  }

  componentDidMount() {
    this.getCategories();
    this.getBuilders();
    this.getEventNames();
    this.getRepairProgresses();
  }

  getData = debounce(() => {
    let crossItems = this.state.crossItems;
    getNetwork()
      // .get(API_ROOT + `/api/v1/roadway/getforregion/${this.props.user.userId}`)
      .get(API_ROOT + '/api/v1/roadway/get')
      .then((r) => {
        let roads = r.data.sort((a, b) => {
          return a.id - b.id;
        });
        this.setState({ roads });
        let arr = [];
        if (crossItems) {
          crossItems.forEach((element) => {
            let index = roads
              .map((e) => {
                return e.id;
              })
              .indexOf(element.itemId);
            arr.push(index + '');
          });
          this.setState({ selectedItems: arr });
        }
        this.setState({ roadsLoading: false });
      });
  }, 500);

  getBuilders() {
    this.setState({ loading: true });
    getNetwork().post(API_ROOT + '/api/v1/roadway/contractor/all').then((r) => {
      let builderList = r.data;
      this.setState({ builderList, loading: false });
    });
  }

  getCategories() {
    getNetwork().post(API_ROOT + '/api/v1/roadway/workcategory/all').then((r) => {
      let categoryList = r.data;
      this.setState({ categoryList });
    });
  }

  getEventNames() {
    getNetwork().post(API_ROOT + '/api/v1/roadway/eventname/all').then((r) => {
      let eventList = r.data;
      this.setState({ eventList });
    });
  }

  getRepairProgresses() {
    getNetwork().post(API_ROOT + '/api/v1/roadway/repairprogress/all').then((r) => {
      let repairProgressList = r.data;
      this.setState({ repairProgressList });
    });
  }

  getRoadById(id) {
    this.state({ loading: true });
    getNetwork().post(API_ROOT + '/api/v1/roadway/get/' + id).then((r) => {
      let road = r.data;
      setTimeout(() => {
        let coordinates = this.parseMapCoords(road.coords);
        let geometry = {
          type: 'LineString',
          coordinates,
        };
        if (road.objectType === 2) {
          geometry.type = 'Point';
          geometry.coordinates = geometry.coordinates[0];
        } else if (road.objectType === 3) {
          geometry.type = 'Polygon';
          let arr = [];
          arr.push(geometry.coordinates);
          geometry.coordinates = arr;
        }
        let line = new this.ymaps.GeoObject(
          {
            geometry,
          },
          {
            draggable: true,
          }
        );
        this.map.geoObjects.add(line);
        line.editor.startEditing();

        if (road.isCross) {
          this.getData();
        }
        let nextState = road;

        this.form.current.setFieldsValue({ eventName: nextState.eventName });
        this.form.current.setFieldsValue({
          workCategory: nextState.workCategory,
        });
        this.form.current.setFieldsValue({ street: nextState.street });
        nextState.line = line;
        nextState.coords = this.parseMapCoords(nextState.coords);
        this.setState(nextState);
        this.setState({ loading: false });
      }, 4000);
    });
  }

  parseMapCoords(coords) {
    return coords.map((item) => {
      return [item.x, item.y];
    });
  }

  onApiAvaliable(ymaps) {
    this.setState({ loading: true });
    this.ymaps = ymaps;
    let id = this.props.location.search.split('=')[1];
    if (!id) {
      setTimeout(() => {
        let line = new this.ymaps.Polyline(
          [[53.2775, 69.3883]],
          {},
          {
            strokeColor: '#00000088',
            strokeWidth: 4,
            editorMaxPoints: 40,
          }
        );
        if (this.map) {
          this.map.geoObjects.add(line);
        }
        line.editor.startEditing();
        this.setState({ line, loading: false });
      }, 500);
    } else {
      this.getRoadById(id);
    }
  }

  parseToObject(arr) {
    return arr.map((item, index) => {
      return {
        x: item[0],
        y: item[1],
        orderNum: index,
      };
    });
  }

  checkFunc() {
    let { t } = this.props;
    let payload = {
      eventName: this.state.eventName,
      street: this.state.street,
      site: this.state.site,
      workCategory: this.state.workCategory,
      streetIsBlocked: this.state.streetIsBlocked,
      streetIsBlockedReason: this.state.streetIsBlockedReason,
      streetIsBlockedDescription: this.state.streetIsBlockedDescription,
      roadwayIsOpened: this.state.roadwayIsOpened,
      roadwayIsOpenedReason: this.state.roadwayIsOpenedReason,
      roadwayIsOpenedDescription: this.state.roadwayIsOpenedDescription,
      workplanStart: this.state.workplanStart,
      workplanEnd: this.state.workplanEnd,
      isCross: this.state.isCross,
      comment: this.state.comment,
      builder: this.state.builder,
      objectType: this.state.objectType,
      builderContacts: this.state.builderContacts,
      isIncompleteStartDate: this.state.isIncompleteStartDate,
      isIncompleteEndDate: this.state.isIncompleteEndDate,
      warrantyPeriod: this.state.warrantyPeriod,
      coords: this.state.coords,
      isHidden: this.state.isHidden,
      repairProgress: this.state.repairProgress,
      projectCost: this.state.projectCost,
      amountPlanned: this.state.amountPlanned,
      length: this.state.length,
      acceptedWorkPerson: this.state.acceptedWorkPerson,
      userId: this.props.user.userId,
      deleted: false,
    };
    if (this.state.id) {
      payload.id = this.state.id;
    }
    let coords = this.checkCoords();
    payload.coords = coords;
    if (this.state.isCross) {
      let items = this.state.selectedItems.map((i) => {
        let index = this.state.roads[i].id;
        return {
          itemId: index,
        };
      });
      payload.crossItems = items;
    }

    getNetwork()
      .post(API_ROOT + '/api/v1/roadway/save', JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((res) => {
        if (res.status === 200) {
          message.success(t('addRoadRepairPlan.addedBy'));
          this.setState({
            redirect: true,
          });
        } else {
          message.error(t('error.dataEnteredIncorrectly'));
        }
      })
      .catch(() => {
        message.error(t('error.dataEnteredIncorrectly'));
      });
  }

  changeVal(e, key) {
    let nextState = {
      ...this.state,
      [key]: e.target.value,
    };
    this.setState(nextState);
    if (key === 'isCross' && !this.state.roads[0]) this.getData();
  }

  changeCheckbox(e, key) {
    let nextState = {
      ...this.state,
      [key]: e.target.checked,
    };
    this.setState(nextState);
  }

  changeStartDate(date, dateStr) {
    this.setState({
      workplanStart: dateStr,
    });
  }

  changeEndDate(date, dateStr) {
    this.setState({
      workplanEnd: dateStr,
    });
  }

  selectChange(value) {
    this.setState({
      selectedItems: value,
    });
  }

  changeObjectType(e) {
    this.setState(
      {
        objectType: e.target.value,
      },
      () => this.changeMapObject()
    );
  }

  changeMapObject() {
    this.map.geoObjects.removeAll();
    const { objectType } = this.state;
    if (objectType === 2) {
      let point = new this.ymaps.GeoObject(
        {
          geometry: {
            type: 'Point',
            coordinates: [53.2775, 69.3883],
          },
        },
        {
          draggable: true,
        }
      );
      this.map.geoObjects.add(point);
      this.setState({ line: point });
    } else if (objectType === 3) {
      let myPolygon = new this.ymaps.Polygon([]);
      this.map.geoObjects.add(myPolygon);
      this.setState({ line: myPolygon });
      myPolygon.editor.startDrawing();
    } else if (objectType === 1) {
      let line = new this.ymaps.Polyline(
        [[53.2775, 69.3883]],
        {},
        {
          strokeColor: '#00000088',
          strokeWidth: 4,
          editorMaxPoints: 40,
        }
      );
      this.map.geoObjects.add(line);
      line.editor.startEditing();
      this.setState({ line });
    }
  }

  checkCoords() {
    let { line, objectType } = this.state;
    let coords = line.editor.geometry._coordPath
      ? line.editor.geometry._coordPath._coordinates
      : line.editor.geometry._coordinates;
    if (objectType === 3) {
      coords = coords[0];
    } else if (objectType === 2) {
      let arr = [];
      arr.push(coords);
      coords = arr;
    }
    coords = this.parseToObject(coords);
    return coords;
  }

  handleCategoryChange(value) {
    this.setState({
      workCategory: value,
    });
  }

  handleBuilderChange(value) {
    this.setState({
      builder: value,
    });
  }

  handleRepairProgressChange(value) {
    this.setState({
      repairProgress: value,
    });
  }

  handleEventNameChange(value) {
    this.setState({
      eventName: value,
    });
  }

  handleSubmit = () => {
    this.checkFunc();
  };

  handleSubmitFailed = () => {
    let { t } = this.props;
    message.warning(t('addRoadRepairPlan.pleaseFillInTheHighlightedFields'));
  };

  render() {
    let { t } = this.props;
    const { redirect, roads } = this.state;
    if (redirect) {
      return <Redirect to="/list" />;
    }
    const dateFormat = 'YYYY-MM-DD';
    let children = [];
    for (let i = 0; i < roads.length; i++) {
      children.push(
        <Option key={i}>
          {roads[i].id}.{roads[i].eventName};{roads[i].street};{roads[i].site};
          {roads[i].workCategory};{roads[i].warrantyPeriod};{roads[i].builder}
        </Option>
      );
    }
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    return (
      <BasicLayout>
        <div className="gutter-example">
          <h3>{t('addRoadRepairPlan.addingNewRoadRepairPlan')}</h3>
          <div style={{ marginBottom: '25px' }}>
            <p style={{ margin: '0' }}>{t('addRoadRepairPlan.crossing')}</p>
            <RadioGroup
              value={this.state.isCross}
              onChange={(e) => this.changeVal(e, 'isCross')}
            >
              <RadioButton value={true}>
                {t('addRoadRepairPlan.yes')}
              </RadioButton>
              <RadioButton value={false}>
                {t('addRoadRepairPlan.no')}
              </RadioButton>
            </RadioGroup>
          </div>
          <Spin
            tip={t('addRoadRepairPlan.loadingPleaseWait') + '...'}
            spinning={this.state.loading}
          >
            {!this.state.isCross ? (
              <Form
                ref={this.form}
                onFinish={this.handleSubmit}
                onFinishFailed={this.handleSubmitFailed}
                className="login-form"
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <label>{t('addRoadRepairPlan.responsible')}</label>
                    <Form.Item
                      name="eventName"
                      preserve
                      rules={[
                        {
                          required: true,
                          message: t(
                            'addRoadRepairPlan.pleaseChooseResponsible'
                          ),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder={t(
                          'addRoadRepairPlan.chooseTheResponsible'
                        )}
                        optionFilterProp="children"
                        onChange={this.handleEventNameChange}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.eventList.map((item) => {
                          return (
                            <Option value={item.nameRu} key={item.id}>
                              {item.nameRu}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="street"
                      preserve
                      rules={[
                        {
                          required: true,
                          message: t('addRoadRepairPlan.pleaseEnterTheStreet'),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t('addRoadRepairPlan.street')}
                        onChange={(e) => this.changeVal(e, 'street')}
                      />
                    </Form.Item>
                    <Form.Item>
                      <TextArea
                        placeholder={t('addRoadRepairPlan.plot')}
                        value={this.state.site}
                        onChange={(e) => this.changeVal(e, 'site')}
                      />
                    </Form.Item>
                    <label>{t('addRoadRepairPlan.selectCategoryOfWork')}</label>
                    <Form.Item
                      name="workCategory"
                      preserve
                      rules={[
                        {
                          required: true,
                          message: t('addRoadRepairPlan.pleaseSelectCategory'),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder={t('addRoadRepairPlan.selectCategory')}
                        optionFilterProp="children"
                        onChange={this.handleCategoryChange}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.categoryList.map((item) => {
                          return (
                            <Option value={item.nameRu} key={item.id}>
                              {item.nameRu}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <p style={{ margin: '0' }}>
                        {t('addRoadRepairPlan.overlappingTheStreet')}
                      </p>
                      <RadioGroup
                        value={this.state.streetIsBlocked}
                        onChange={(e) => this.changeVal(e, 'streetIsBlocked')}
                      >
                        <RadioButton value={true}>
                          {t('addRoadRepairPlan.yes')}
                        </RadioButton>
                        <RadioButton value={false}>
                          {t('addRoadRepairPlan.no')}
                        </RadioButton>
                      </RadioGroup>
                    </Form.Item>
                    <Form.Item>
                      <TextArea
                        rows={3}
                        placeholder={t(
                          'addRoadRepairPlan.overlappingTheStreet'
                        )}
                        value={this.state.streetIsBlockedReason}
                        onChange={(e) =>
                          this.changeVal(e, 'streetIsBlockedReason')
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <p style={{ margin: '0' }}>
                        {t('addRoadRepairPlan.openingOfTheRoadway')}
                      </p>
                      <RadioGroup
                        value={this.state.roadwayIsOpened}
                        onChange={(e) => this.changeVal(e, 'roadwayIsOpened')}
                      >
                        <RadioButton value={true}>
                          {t('addRoadRepairPlan.yes')}
                        </RadioButton>
                        <RadioButton value={false}>
                          {t('addRoadRepairPlan.no')}
                        </RadioButton>
                      </RadioGroup>
                    </Form.Item>
                    <Form.Item>
                      <TextArea
                        rows={3}
                        placeholder={t('addRoadRepairPlan.openingOfTheRoadway')}
                        value={this.state.roadwayIsOpenedReason}
                        onChange={(e) =>
                          this.changeVal(e, 'roadwayIsOpenedReason')
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <p style={{ margin: '0' }}>
                        {t('addRoadRepairPlan.objectTypeOnTheMap')}
                      </p>
                      <RadioGroup
                        value={this.state.objectType}
                        onChange={(e) => this.changeObjectType(e)}
                      >
                        <RadioButton value={1}>
                          {t('addRoadRepairPlan.coordinates')}
                        </RadioButton>
                        <RadioButton value={2}>
                          {t('addRoadRepairPlan.label')}
                        </RadioButton>
                        <RadioButton value={3}>
                          {t('addRoadRepairPlan.polygon')}
                        </RadioButton>
                      </RadioGroup>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item>
                      <p>
                        {t('addRoadRepairPlan.dateOfThePlannedStartOfWork')}
                      </p>
                      <DatePicker
                        value={
                          this.state.workplanStart
                            ? moment(this.state.workplanStart, dateFormat)
                            : null
                        }
                        placeholder={t('addRoadRepairPlan.beginningOfWork')}
                        onChange={this.changeStartDate}
                      />
                      <Checkbox
                        style={{ marginLeft: 16 }}
                        checked={this.state.isIncompleteStartDate}
                        onChange={(e) =>
                          this.changeCheckbox(e, 'isIncompleteStartDate')
                        }
                      >
                        {t(
                          'addRoadRepairPlan.incompleteDateOfThePlannedStartOfWork'
                        )}
                      </Checkbox>
                    </Form.Item>

                    <Form.Item>
                      <p> {t('addRoadRepairPlan.plannedCompletionDate')}</p>
                      <DatePicker
                        value={
                          this.state.workplanEnd
                            ? moment(this.state.workplanEnd, dateFormat)
                            : null
                        }
                        placeholder={t('addRoadRepairPlan.completionOfWork')}
                        onChange={this.changeEndDate}
                      />
                      <Checkbox
                        style={{ marginLeft: 16 }}
                        checked={this.state.isIncompleteEndDate}
                        onChange={(e) =>
                          this.changeCheckbox(e, 'isIncompleteEndDate')
                        }
                      >
                        {t(
                          'addRoadRepairPlan.incompleteDateOfThePlannedCompletionOfWork'
                        )}
                      </Checkbox>
                    </Form.Item>

                    <Form.Item>
                      <label> {t('addRoadRepairPlan.chooseContractor')}</label>
                      <Select
                        showSearch
                        placeholder={t('addRoadRepairPlan.chooseContractor')}
                        optionFilterProp="children"
                        onChange={this.handleBuilderChange}
                        value={this.state.builder}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.builderList.map((item) => {
                          return (
                            <Option value={item.nameRu} key={item.id}>
                              {item.nameRu}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item>
                      <Input
                        placeholder={t(
                          'addRoadRepairPlan.responsibleRepairContacts'
                        )}
                        value={this.state.builderContacts}
                        onChange={(e) => this.changeVal(e, 'builderContacts')}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Input
                        placeholder={t('addRoadRepairPlan.warrantyPeriod')}
                        value={this.state.warrantyPeriod}
                        onChange={(e) => this.changeVal(e, 'warrantyPeriod')}
                      />
                    </Form.Item>

                    <Form.Item>
                      <label>
                        {t('addRoadRepairPlan.selectRepairExecutionStatus')}
                      </label>
                      <Select
                        showSearch
                        placeholder={t(
                          'addRoadRepairPlan.selectRepairExecutionStatus'
                        )}
                        optionFilterProp="children"
                        onChange={this.handleRepairProgressChange}
                        value={this.state.repairProgress}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.repairProgressList.map((item) => {
                          return (
                            <Option value={item.nameRu} key={item.id}>
                              {item.nameRu}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item>
                      <Input
                        placeholder={
                          t('addRoadRepairPlan.projectCost') + '(млн.тг)'
                        }
                        value={this.state.projectCost}
                        onChange={(e) => this.changeVal(e, 'projectCost')}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Input
                        placeholder={
                          t('addRoadRepairPlan.theAmountPlannedFor2021') +
                          '(млн.тг)'
                        }
                        value={this.state.amountPlanned}
                        onChange={(e) => this.changeVal(e, 'amountPlanned')}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Input
                        placeholder={t('addRoadRepairPlan.length') + '(км)'}
                        value={this.state.length}
                        onChange={(e) => this.changeVal(e, 'length')}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Input
                        placeholder={t('addRoadRepairPlan.whoAcceptedTheWork')}
                        value={this.state.acceptedWorkPerson}
                        onChange={(e) =>
                          this.changeVal(e, 'acceptedWorkPerson')
                        }
                      />
                    </Form.Item>

                    <Form.Item>
                      <TextArea
                        rows={3}
                        placeholder={t('addRoadRepairPlan.comments')}
                        value={this.state.comment}
                        onChange={(e) => this.changeVal(e, 'comment')}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Checkbox
                        value={this.state.isHidden}
                        onChange={(e) => this.changeVal(e, 'isHidden')}
                      >
                        {t('addRoadRepairPlan.hideThisRoadRepairPlan')}
                      </Checkbox>
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                      {t('addRoadRepairPlan.addTo')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            ) : (
              <div style={{ marginBottom: '40px' }}>
                <Spin indicator={antIcon} spinning={this.state.roadsLoading}>
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={t('addRoadRepairPlan.selectRoadPlans')}
                    onChange={this.selectChange}
                    value={this.state.selectedItems}
                  >
                    {children}
                  </Select>
                </Spin>
              </div>
            )}
          </Spin>
          <div className="map-wrap" style={{ marginTop: 16, border: '1px solid #d9d9d9' }}>
            <YMaps onApiAvaliable={(ymaps) => this.onApiAvaliable(ymaps)}>
              <Map
                state={{
                  center: this.state.center,
                  zoom: this.state.zoom,
                }}
                width="100%"
                height="100%"
                instanceRef={(ref) => (this.map = ref)}
              />
            </YMaps>
          </div>
          <div style={{ marginTop: '20px' }}>
            {this.state.isCross && (
              <Button type="primary" onClick={() => this.checkFunc()}>
                {t('addRoadRepairPlan.addTo')}
              </Button>
            )}
          </div>
        </div>
      </BasicLayout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default withTranslation()(connect(mapStateToProps)(RoadRepairPage));
