import { Button, Spin, Table, message, Checkbox, Divider, Popconfirm } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ROOT } from '../../../../../config';
import { groupData } from '../../../utils';
import moment from 'moment';
import { summaryTable } from '../../../utils/SummaryTable';
import { COLUMNS } from './constants';
import { FiltersChart } from './components/FiltersChart/FiltersChart';
import { ExcelButton } from './components/ExcelButton/ExcelButton';
import { getNetwork } from '../../../../../utils/getNetwork';
import styles from './Chart.module.scss';
import { EditChart } from './components/EditChart';
import { checkRole } from '../../../../../utils/CheckRoleWithToken';
import { v4 as uuidv4 } from 'uuid';

const roles = ['all', 'akm'];
const { Group: CheckboxGroup } = Checkbox;

export const Chart = ({ isAction }) => {
  const { t } = useTranslation();
  const [reports, setReports] = useState([]);
  const [createdReports, setCreatedReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isListReport, setIsListReport] = useState(true);
  const [isConsolidatedReport, setIsConsolidatedReport] = useState(false);
  const ref = useRef(null);
  const [formValues, setFormValues] = useState(
    JSON.parse(localStorage.getItem('formReport')) || {
      chartType: null,
      org: null,
      district: null,
      year: null,
      quarter: null,
      month: null,
    }
  );

  const [formData, setFormData] = useState({
    chartTypes: [],
    orgs: [],
    years: [],
    months: [],
    quarter: [1, 2, 3, 4],
    district: [],
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditAllowed, setEditAllowed] = useState(false);

  const handleClickReportDelete = (record) => async () => {
    const report = formData.chartTypes.find((item) => item.reportTitle.includes(record.reportName));
    const organizationItem = formData.orgs.find(
      (item) => item.organization === record.organization
    );
    const yearItem = formData.years.find((item) => item.year === record.year);
    const monthItem = formData.months.find((item) => item.month === record.month);

    const organizationId = organizationItem.id;
    const yearId = yearItem.id;
    const monthId = monthItem.id;

    const queryParam = new URLSearchParams({ organizationId, yearId, monthId }).toString();

    if (report) {
      const result = await getNetwork().delete(
        API_ROOT + `/myReport/report${report.id}?${queryParam}`
      );
      if (result && result.status === 200) {
        message.success(
          `Отчет ${record.organization && record.month && record.year
            ? 'от ' + record.organization + ' за ' + record.month + ' ' + record.year
            : ''
          } успешно удален!`
        );
        requestCreatedReadings();
      } else {
        message.error('Вы не имеете права удалять данный отчет!');
      }
    }
  };

  const handleClickReportEdit = (record) => async () => {
    const { chartTypes, orgs, years, months } = formData;
    const { reportName, organization, district, year, month } = record;

    const yearId = years.find((item) => item.year === record.year).id;
    const monthId = months.find((item) => item.month === record.month).id;

    const queryParam = new URLSearchParams({
      yearId: yearId,
      monthId: monthId,
    }).toString();

    const result = await getNetwork().get(
      API_ROOT + `/myReport/report-upd-period-check?${queryParam}`
    );

    if (result && result.status === 200) {
      if (result.data.isEnabled) {
        setIsModalOpen(true);
        setFormValues((prev) => ({
          ...prev,
          chartType: chartTypes.find((chart) => chart.reportTitle.includes(reportName)).id,
          org: orgs.find((orgObj) => orgObj.organization.includes(organization)).id,
          district,
          year: years.find((yearObj) => yearObj.year.includes(year)).id,
          month: months.find((monthObj) => monthObj.month.includes(month)).id,
        }));
      } else {
        message.error('Срок редактирования данного отчета истёк');
        if (isEditAllowed) {
          setIsModalOpen(true);
          setFormValues((prev) => ({
            ...prev,
            chartType: chartTypes.find((chart) => chart.reportTitle.includes(reportName)).id,
            org: orgs.find((orgObj) => orgObj.organization.includes(organization)).id,
            district,
            year: years.find((yearObj) => yearObj.year.includes(year)).id,
            month: months.find((monthObj) => monthObj.month.includes(month)).id,
          }));
        }
      }
    }
  };

  const getColumnSearchProps = (name) => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, clearFilters, confirm, close }) => (
      <div className={styles.box}>
        <div>
          <CheckboxGroup
            className={styles.checkboxGroup}
            options={createdReports.reduce((acc, cur) => {
              if (!acc.includes(cur[name])) {
                acc.push(cur[name]);
              }

              return acc;
            }, [])}
            value={selectedKeys}
            onChange={setSelectedKeys}
          />
        </div>
        <Divider className={styles.divider} />
        <div className={styles.wrapButton}>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
          >
            Сбросить
          </Button>
          <Button
            onClick={() => {
              close();
              confirm();
            }}
            type="primary"
          >
            Выбрать
          </Button>
        </div>
      </div>
    ),
    onFilter: (value, record) => record[name] === value,
  });

  const createdReportsColumns = [
    {
      title: 'Наименование отчета',
      dataIndex: 'reportName',
      width: '40%',
      render: (text, record) => (
        <span className={styles.reportTitle} onClick={handleRowClick(record)}>
          {text}
        </span>
      ),
      ...getColumnSearchProps('reportName'),
    },
    {
      title: 'Район',
      dataIndex: 'district',
      ...getColumnSearchProps('district'),
    },
    {
      title: 'Организация',
      dataIndex: 'organization',
      ...getColumnSearchProps('organization'),
    },
    {
      title: 'Год',
      width: 80,
      dataIndex: 'year',
      ...getColumnSearchProps('year'),
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.year - b.year,
    },
    {
      title: 'Месяц/квартал',
      width: 120,
      dataIndex: 'month',
      ...getColumnSearchProps('month'),
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment().month(a.month).format('M') - moment().month(b.month).format('M'),
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      width: 120,
    },
    {
      title: 'Действие',
      render: (_, record) => (
        <div>
          <span id="edit" className={styles.tableButton} onClick={handleClickReportEdit(record)}>
            {t('intersections.edit')}
          </span>
          {isAction ? (
            <Popconfirm
              title={`
              ${t('intersections.exactlyWantToDelete')} ${record.organization && record.month && record.year
                  ? ' ' + record.organization + ' за ' + record.month + ' ' + record.year
                  : ''
                } ?`}
              onConfirm={handleClickReportDelete(record)}
              okText="Да"
              onCancel={(e) => e.stopPropagation()}
              cancelText="Отмена"
            >
              <a href="#" className={styles.tableButton}>
                {t('intersections.delete')}
              </a>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];

  const getData = useCallback(() => {
    const paths = [
      '/reestruslug/get/organization/id',
      '/myReport/getTitle',
      '/myReport/getYear',
      '/myReport/getMonth',
      '/myReport/getDistrict',
    ];
    Promise.all(paths.map((path) => getNetwork().get(`${API_ROOT}${path}`)))
      .then((responses) => responses.map((response) => response.data))
      .then(([orgs, chartTypes, years, months, district]) =>
        setFormData((prev) => ({ ...prev, orgs, chartTypes, years, months, district }))
      );
  }, []);

  const requestCreatedReadings = useCallback(async () => {
    setLoading(true);
    const result = await getNetwork().get(API_ROOT + '/myReport/reports');
    if (result.status === 200) {
      setCreatedReports(changeCreatedReportsData(result.data));
    }
    setLoading(false);
  }, []);

  const changeCreatedReportsData = (dataReports) => {
    // eslint-disable-next-line no-unused-vars
    return Object.entries(dataReports).flatMap(([_, { data, category }]) =>
      data.map(({ district, organization, year, month, createdAt }) => ({
        key: uuidv4(),
        reportName: category,
        district,
        organization,
        year,
        month,
        createdAt: createdAt ? moment(createdAt).format('DD.MM.YYYY') : '',
      }))
    );
  };

  const handleRowClick = (record) => () => {
    setFormValues((prev) => ({
      ...prev,
      chartType: formData.chartTypes.find((chart) => chart.reportTitle.includes(record.reportName))
        .id,
      org: formData.orgs.find((orgObj) => orgObj.organization.includes(record.organization)).id,
      district: record.district,
      year: formData.years.find((yearObj) => yearObj.year.includes(record.year)).id,
      month: formData.months.find((monthObj) => monthObj.month.includes(record.month)).id,
    }));
    setIsListReport(false);
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
    setReports([]);
    setFormValues({
      chartType: null,
      org: null,
      year: null,
      month: null,
      district: null,
    });
  };

  useEffect(() => {
    if (formValues.chartType === 1) {
      const listTh = document.querySelectorAll('th');
      listTh.forEach((th) => {
        if (!th.innerHTML) {
          th.remove();
        }
      });
    }
    localStorage.setItem('formReport', JSON.stringify(formValues));
  }, [formValues]);

  useEffect(() => {
    getData();
    requestCreatedReadings();
  }, [getData, requestCreatedReadings]);

  useEffect(() => {
    const roleList = checkRole();
    const result = roleList.filter((role) => roles.includes(role));
    if (result.length) {
      setEditAllowed(true);
    }
  }, []);

  useEffect(() => {
    if (!isListReport && ref.current) {
      const headers = ref.current.querySelectorAll('th');
      headers.forEach(th => {
        if (!th.textContent.trim()) {
          th.remove();
        }
      });
    }
  }, [isListReport]);

  return (
    <div>
      <FiltersChart
        formValues={formValues}
        formData={formData}
        setReports={setReports}
        setCreatedReports={setCreatedReports}
        setFormValues={setFormValues}
        isListReport={isListReport}
        setIsListReport={setIsListReport}
        setLoading={setLoading}
        setIsConsolidatedReport={setIsConsolidatedReport}
        isModalOpen={isModalOpen}
        requestCreatedReadings={requestCreatedReadings}
      />
      {formData.chartTypes.filter(i => i.id === formValues.chartType)[0] && (
        <ExcelButton
          formData={formData}
          formValues={formValues}
          reports={reports}
          isConsolidatedReport={isConsolidatedReport}
        />
      )}
      <Spin tip={t('intersections.loadingPleaseWait') + '...'} spinning={loading}>
        {isListReport ? (
          <Table
            bordered
            size="middle"
            columns={createdReportsColumns}
            dataSource={createdReports}
          />
        ) : (
          <Table
            key='table'
            ref={ref}
            columns={COLUMNS[formValues.chartType] || []}
            dataSource={formValues.chartType === 3 ? groupData(reports) : reports}
            className={`table-${formValues.chartType} ${styles.table}`}
            bordered
            pagination={false}
            scroll={{
              x: formValues.chartType === 1 ? 1700 : null,
              y: window.innerHeight - 100,
            }}
            summary={formValues.chartType === 3 ? summaryTable(reports) : null}
          />
        )}
      </Spin>
      <Modal
        title={`Редактирование отчета - ${formValues.district}`}
        open={isModalOpen}
        width={'100%'}
        onOk={() => setIsModalOpen(true)}
        onCancel={handleCancelModal}
        footer={false}
      >
        <EditChart
          reports={reports}
          setReports={setReports}
          formValues={formValues}
          handleCancelModal={handleCancelModal}
        />
      </Modal>
    </div>
  );
};
