import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import {
  BranchesOutlined,
  DragOutlined,
  PlusOutlined,
  TeamOutlined,
  ToolOutlined,
  DiffOutlined,
  AuditOutlined,
} from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
const { Sider } = Layout;

let NAV_ITEMS = [
  // {
  //   title: 'sideBar.roadRepairPlans',
  //   link: '/list',
  //   icon: <BranchesOutlined />,
  // },
  {
    title: 'Госуслуги',
    link: '/gosuslugy',
    icon: <AuditOutlined />,
  },
  // {
  //   title: 'sideBar.intersections',
  //   link: '/crosses',
  //   icon: <DragOutlined />,
  // },
  // {
  //   title: 'sideBar.addRoadRepairPlan',
  //   link: '/list/add',
  //   icon: <PlusOutlined />,
  // },
  {
    title: 'sideBar.configuringUsers',
    link: '/users/list',
    icon: <TeamOutlined />,
  },
  {
    title: 'sideBar.settingUpRoles',
    link: '/roles/list',
    icon: <ToolOutlined />,
  },
  // {
  //   title: 'sideBar.settingUpDirectories',
  //   link: '/catalogs',
  //   icon: <DiffOutlined />,
  // },
  // {
  //   title: 'sideBar.makeSchedule',
  //   link: '/passenger-transport',
  // },
  {
    title: 'sideBar.userActions',
    link: '/user-actions',
  },
];

class SidebarComponent extends Component {
  state = {
    collapsed: true,
  };

  render() {
    let { t, collapsed } = this.props;

    const route = this.props.route.pathname;
    NAV_ITEMS.forEach((it) => {
      if (it.link === route) it.active = true;
      else it.active = false;
    });
    return (
      <Sider
        width={200}
        style={{ background: '#fff', display: collapsed ? 'none' : 'block' }}
        collapsible
        collapsed={collapsed}
      >
        <Menu mode="inline" style={{ height: '100%', borderRight: 0 }}>
          {NAV_ITEMS.map((item, index) => (
            <Menu.Item
              key={index}
              className={item.active ? 'ant-menu-item-selected' : ''}
            >
              {item.icon}
              <Link
                to={item.link}
                style={{ display: 'initial', color: 'initial' }}
              >
                {!collapsed && <span>{t(item.title)}</span>}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
    );
  }
}
export default withTranslation()(SidebarComponent);
