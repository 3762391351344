import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ user, module, component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        user.userId ? (
          module.currentModuleStatus ? (
            <Component {...props} />
          ) : (
            <Redirect to="/error" />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return { user: state.user, module: state.module };
};

export default connect(mapStateToProps)(PrivateRoute);
