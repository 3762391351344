import { SET_MODULES, SET_MODULE_STATUS } from './type';

export const setModules = (modules) => ({
  type: SET_MODULES,
  payload: modules,
});

export const setModuleStatus = (moduleStatus) => ({
  type: SET_MODULE_STATUS,
  payload: moduleStatus,
});
