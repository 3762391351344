import React, { useEffect } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import { getNetwork } from '../../../../../../../utils/getNetwork';
import { API_ROOT } from '../../../../../../../config';

const { useForm } = Form;

const arrFormFields = [
  {
    id: 1, label: 'Перевозчик', name: 'transporter', placeholder: 'Введите название перевозчика', rules: [
      { whitespace: true, message: 'Пожалуйста введите название перевозчика' },
      { required: true, message: 'Пожалуйста введите название перевозчика' },
    ]
  },
  { id: 2, label: 'БИН/ИИН', name: 'binInn', placeholder: 'Введите БИН/ИНН', rules: [] },
  { id: 3, label: 'ФИО руководителя', name: 'fullnameHead', placeholder: 'Введите ФИО', rules: [] },
  { id: 4, label: 'Адрес', name: 'address', placeholder: 'Введите адрес', rules: [] },
  {
    id: 5, label: 'Телефон 1', name: 'phoneNumber1', placeholder: 'Введите телефон', rules: [
      { pattern: new RegExp(/^[0-9]+$/), message: 'Введите правильный номер' }]
  },
  {
    id: 6, label: 'Телефон 2', name: 'phoneNumber2', placeholder: 'Введите телефон', rules: [
      { pattern: new RegExp(/^[0-9]+$/), message: 'Введите правильный номер' }]
  },
  {
    id: 7, label: 'Телефон 3', name: 'phoneNumber3', placeholder: 'Введите телефон', rules: [
      { pattern: new RegExp(/^[0-9]+$/), message: 'Введите правильный номер' }]
  },
  {
    id: 8, label: 'E-mail', name: 'email', placeholder: 'Введите email', rules: [
      { type: 'email', message: 'Пожалуйста введите существующий email' },
    ]
  },
];

export const ModalTransporter = ({
  isModal,
  setIsModal,
  requestGetTransporters,
  initialFormValues,
  setInitialFormValues,
  request,
}) => {
  const [form] = useForm();

  const requestChangeTransporters = async (body) => {
    const result = await getNetwork().put(
      API_ROOT + '/passangers/transporters',
      {
        ...body,
        id: initialFormValues.id,
        transporter: body.transporter.trim()
      }
    );
    if (result.status === 200) {
      message.success(`Перевозчик ${result.data.transporter || ''} успешно изменен`);
      requestGetTransporters();
      request();
    }

    if (result.status >= 500) {
      message.error('Сервер временно недоступен, попробуйте позже');
    }
  };

  const requestCreateTransporters = async (body) => {
    console.log(body);
    const result = await getNetwork().post(
      API_ROOT + '/passangers/transporters',
      {
        ...body,
        transporter: body.transporter
      }
    );
    if (result.status === 200) {
      message.success(`Перевозчик ${result.data.transporter || ''} успешно добавлен`);
      requestGetTransporters();
      request();
    }

    if (result.status >= 500) {
      message.error('Сервер временно недоступен, попробуйте позже');
    }
  };

  const requestForm = async (body) => {
    if (initialFormValues) {
      requestChangeTransporters(body);
    } else {
      requestCreateTransporters(body);
    }

    handleCancel();
  };

  const handleCancel = () => {
    form.resetFields();
    setInitialFormValues(null);
    setIsModal(false);
  };

  const onFinish = (values) => {
    requestForm(values);
  };

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, [initialFormValues]);

  return (
    <Modal
      title={initialFormValues ? 'Редактирование перевозчика' : 'Новый перевозчик'}
      open={isModal}
      onCancel={handleCancel}
      footer={[
        <Button key="add" type="primary" htmlType="submit" form="addTransporterForm">
          Отправить
        </Button>,
        <Button key="back" onClick={handleCancel}>
          Отменить
        </Button>,
      ]}
    >
      <Form
        form={form}
        id="addTransporterForm"
        name="formTransporter"
        labelCol={{ span: 8 }}
        layout='vertical'
        onFinish={onFinish}
      >
        {arrFormFields.map((field) => (
          <Form.Item
            key={field.id}
            label={field.label}
            name={field.name}
            rules={field.rules}
          >
            <Input placeholder={field.placeholder} />
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};