import React, { useCallback, useState } from 'react';
import { InputNumber } from 'antd';
import { EDITABLE_LIST_SERVICE_CODES } from '../../../ChartBuilder/constants';
import { useTranslation } from 'react-i18next';
import styles from './EditChart.module.scss';

export const EditableColumns = ({
  formValues,
  getTotalField,
  checkModifyElement,
  setReports,
  updateData,
}) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const validateField = useCallback(
    (id, value) => {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (value === null) {
          newErrors[id] = t('intersections.obligatoryField');
        } else {
          delete newErrors[id];
        }
        return newErrors;
      });
    },
    [t]
  );

  const handleChangeInputNumber = useCallback(
    (fieldTitle, elem, fieldId) => (value) => {
      const updatedField = { ...elem, [fieldTitle]: value };
      if (formValues.chartType === 3) {
        setReports((prev) =>
          prev.map((item) => {
            if (item.subcategoryId === elem.subcategoryId) {
              return {
                ...item,
                count: value,
              };
            }
            return item;
          })
        );
      } else {
        setReports((prev) =>
          prev.map((item) => {
            if (item.serviceCode === updatedField.serviceCode) {
              return updateData(updatedField);
            }
            return item;
          })
        );
      }
      validateField(fieldId, value);
    },
    [formValues.chartType, updateData, setReports, validateField]
  );

  const generateFieldForm = useCallback(
    (name, elem) => {
      if (elem.nameOfService === 'Итого') {
        return <span>{getTotalField(name)}</span>;
      }

      const fieldId = `${formValues.chartType === 3 ? elem.key : elem.serviceCode}__${name}`;
      return (
        <>
          <InputNumber
            className={errors[fieldId] && styles['input-error']}
            min={0}
            disabled={
              !EDITABLE_LIST_SERVICE_CODES.includes(elem.serviceCode) &&
              checkModifyElement(name, elem.isAvailableEdit)
            }
            defaultValue={elem[name] || 0}
            name={fieldId}
            onChange={handleChangeInputNumber(name, elem, fieldId)}
          />
          {errors[fieldId] && <div className={styles.error}>{errors[fieldId]}</div>}
        </>
      );
    },
    [checkModifyElement, formValues.chartType, handleChangeInputNumber, getTotalField, errors]
  );

  const COLUMNS = {
    1: [
      {
        title: 'Код услуги',
        dataIndex: 'serviceCode',
      },
      {
        title: 'Наименование государственных услуг',
        dataIndex: 'nameOfService',
        width: 200,
      },
      {
        title: 'ВСЕГО (количество оказанных госуслуг)',
        dataIndex: 'summary',
        rowSpan: 3,
        children: [
          {
            children: [
              {
                children: [
                  {
                    title: 'физ. лица',
                    dataIndex: 'summaryFiz',
                    align: 'center',
                    className: 'fizColumn summaryColumn',
                    render: (text, record) => {
                      if (record.nameOfService === 'Итого') {
                        return <span>{getTotalField('summaryFiz')}</span>;
                      }

                      return <span>{text}</span>;
                    },
                  },
                  {
                    title: 'юр. лица',
                    dataIndex: 'summaryYr',
                    align: 'center',
                    className: 'yrColumn summaryColumn',
                    render: (text, record) => {
                      if (record.nameOfService === 'Итого') {
                        return <span>{getTotalField('summaryYr')}</span>;
                      }
                      return <span>{text}</span>;
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'непосредственно оказанных через:',
        children: [
          {
            title: 'Государственная корпорация «Правительство для граждан»',
            rowSpan: 2,
            children: [
              {
                children: [
                  {
                    title: 'физ. лица',
                    dataIndex: 'serviceCountByStateorgPeopleFiz',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('serviceCountByStateorgPeopleFiz', record),
                  },
                  {
                    title: 'юр. лица',
                    dataIndex: 'serviceCountByStateorgPeopleYr',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('serviceCountByStateorgPeopleYr', record),
                  },
                ],
              },
            ],
          },
          {
            title: 'Портал электронного правительство',
            rowSpan: 2,
            children: [
              {
                children: [
                  {
                    title: 'физ. лица',
                    dataIndex: 'serviceCountByStateorgCompaniesFiz',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('serviceCountByStateorgCompaniesFiz', record),
                  },
                  {
                    title: 'юр. лица',
                    dataIndex: 'serviceCountByStateorgCompaniesYr',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('serviceCountByStateorgCompaniesYr', record),
                  },
                ],
              },
            ],
          },
          {
            title: 'государственный орган:',
            children: [
              {
                title: 'В бумажной форме',
                children: [
                  {
                    title: 'физ. лица',
                    dataIndex: 'inPeperFormFiz',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) => generateFieldForm('inPeperFormFiz', record),
                  },
                  {
                    title: 'юр. лица',
                    dataIndex: 'inPeperFormYr',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) => generateFieldForm('inPeperFormYr', record),
                  },
                ],
              },
              {
                title:
                  'электронный вид через информационные системы услугодателя без прямого контакта' +
                  ' с услугополучателем (за исключением www.egov.kz, www.elicense.kz)',

                children: [
                  {
                    title: 'физ. лица',
                    dataIndex: 'electronicalyThroughtInformationSystemsFiz',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('electronicalyThroughtInformationSystemsFiz', record),
                  },
                  {
                    title: 'юр. лица',
                    dataIndex: 'electronicalyThroughtInformationSystemsYr',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('electronicalyThroughtInformationSystemsYr', record),
                  },
                ],
              },
              {
                title:
                  'электронный вид через информационные системы услугодателя путем прямого контакта с услугополучателем' +
                  ' и ручного ввода заявки в информационную систему (за исключением www.egov.kz, www.elicense.kz)',
                children: [
                  {
                    title: 'физ. лица',
                    dataIndex: 'throughTheServiceProviderFiz',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('throughTheServiceProviderFiz', record),
                  },
                  {
                    title: 'юр. лица',
                    dataIndex: 'throughTheServiceProviderYr',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) => generateFieldForm('throughTheServiceProviderYr', record),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    2: [
      {
        title: 'Код услуги',
        dataIndex: 'serviceCode',
      },
      {
        title: 'Наименование государственных услуг',
        dataIndex: 'nameOfService',
        width: 200,
      },
      {
        title: 'Количество обоснованных',
        children: [
          {
            title: 'Выданных в электроннном виде',
            children: [
              {
                title: 'физ. лица',
                dataIndex: 'justifiedElectronicFiz',
                className: 'fizColumn',
                render: (_, record) => generateFieldForm('justifiedElectronicFiz', record),
              },
              {
                title: 'юр. лица',
                dataIndex: 'justifiedElectronicYr',
                className: 'yrColumn',
                render: (_, record) => generateFieldForm('justifiedElectronicYr', record),
              },
            ],
          },
          {
            title: 'Выданных в бумажном виде',
            children: [
              {
                title: 'физ. лица',
                dataIndex: 'justifiedInPaperFromFiz',
                className: 'fizColumn',
                render: (_, record) => generateFieldForm('justifiedInPaperFromFiz', record),
              },
              {
                title: 'юр. лица',
                dataIndex: 'justifiedInPaperFromYr',
                className: 'yrColumn',
                render: (_, record) => generateFieldForm('justifiedInPaperFromYr', record),
              },
            ],
          },
        ],
      },
      {
        title: 'Количество необоснованных',
        children: [
          {
            title: 'Выданных в электроннном виде',
            children: [
              {
                title: 'физ. лица',
                dataIndex: 'unsubstantiatedElectronicFiz',
                className: 'fizColumn',
                render: (_, record) => generateFieldForm('unsubstantiatedElectronicFiz', record),
              },
              {
                title: 'юр. лица',
                dataIndex: 'unsubstantiatedElectronicYr',
                className: 'yrColumn',
                render: (_, record) => generateFieldForm('unsubstantiatedElectronicYr', record),
              },
            ],
          },
          {
            title: 'Выданных в бумажном виде',
            children: [
              {
                title: 'физ. лица',
                dataIndex: 'unsubstantiatedInPaperFromFiz',
                className: 'fizColumn',
                render: (_, record) => generateFieldForm('unsubstantiatedInPaperFromFiz', record),
              },
              {
                title: 'юр. лица',
                dataIndex: 'unsubstantiatedInPaperFromYr',
                className: 'yrColumn',
                render: (_, record) => generateFieldForm('unsubstantiatedInPaperFromYr', record),
              },
            ],
          },
        ],
      },
    ],
    3: [
      {
        title: '№ п/п',
        dataIndex: 'number',
        width: 110,
      },
      {
        title: 'Выполняемые мероприятия',
        dataIndex: 'subcategory',
        width: 500,
      },
      {
        title: 'Количество',
        dataIndex: 'count',
        align: 'center',
        width: 110,
        render: (_, record) =>
          Array.isArray(record.children) && record.children.length
            ? record.count
            : generateFieldForm('count', record),
      },
    ],
  };
  return {
    columns: COLUMNS[formValues.chartType],
    errors,
  };
};
