import React, { useState, useEffect, useMemo } from 'react';
import { Button, Table, message, Spin, Input } from 'antd';
import tabStyles from '../tabsStyles.module.scss';
import styles from './CreateDirectoryInterpreterTab.module.scss';
import { getNetwork } from '../../../../../utils/getNetwork';
import { API_ROOT } from '../../../../../config';
import { useTranslation } from 'react-i18next';
import { ModalTransporter } from './components/ModalTransporter/ModalTransporter';
import { ExportTransporters } from './ExportTransporters';
const { Search } = Input;

export const CreateDirectoryInterpreterTab = ({ request }) => {
  const { t } = useTranslation();
  const [isModal, setIsModal] = useState(false);
  const [transporters, setTransporters] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState(null);

  const columns = useMemo(() => [
    {
      title: '№',
      dataIndex: 'number',
      width: 50,
      fixed: 'left',
    },
    {
      title: 'Наименование перевозчика',
      dataIndex: 'transporter',
      fixed: 'left',
    },
    {
      title: 'БИН/ИИН',
      dataIndex: 'binInn',
      render: (text) => (
        <span>{text || '–'}</span>
      )
    },
    {
      title: 'ФИО руководителя',
      dataIndex: 'fullnameHead',
      width: '15%',
      render: (text) => (
        <span>{text || '–'}</span>
      )
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      width: '20%',
      render: (text) => (
        <span>{text || '–'}</span>
      )
    },
    {
      title: 'Телефон 1',
      dataIndex: 'phoneNumber1',
      render: (text) => (
        <span>{text || '–'}</span>
      )
    },
    {
      title: 'Телефон 2',
      dataIndex: 'phoneNumber2',
      render: (text) => (
        <span>{text || '–'}</span>
      )
    },
    {
      title: 'Телефон 3',
      dataIndex: 'phoneNumber3',
      render: (text) => (
        <span>{text || '–'}</span>
      )
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      render: (text) => (
        <span>{text || '–'}</span>
      )
    },
    {
      title: 'Действие',
      width: 150,
      align: 'center',
      fixed: 'right',
      render: (_, record) => <Button type="link" onClick={handleClickChangeSettleventsButton(record)}>Редактировать</Button>
    },
  ], []);

  const handleClickChangeSettleventsButton = (record) => () => {
    setInitialFormValues(record);
    setIsModal(true);
  };

  const requestGetTransporters = async () => {
    setIsLoading(true);
    const result = await getNetwork().get(API_ROOT + '/passangers/transporters');

    if (result.status === 200) {
      setTransporters(result.data);
      setSearchData(result.data);
    }

    if (result.status >= 500) {
      message.error('Сервер временно недоступен, попробуйте позже');
    }
    setIsLoading(false);
  };

  const handleClickAddRoute = () => {
    setIsModal(true);
  };

  const onSearch = (value) => {
    setSearchValue(value);
    setSearchData(transporters.filter((item) => item.transporter.toLowerCase().includes(value.toLowerCase())));
  };

  useEffect(() => {
    requestGetTransporters();
  }, []);

  return (
    <>
      <div className={styles.box}>
        <div>
          <Button
            className={tabStyles.addButton}
            onClick={handleClickAddRoute}
            type='primary'
            disabled={!transporters.length}
          >
            Добавить перевозчика
          </Button>
          <Search
            className={tabStyles.search}
            defaultValue={searchValue}
            placeholder="Поиск по названию перевозчика"
            onSearch={onSearch}
            enterButton
          />
        </div>
        <div>
          <ExportTransporters
            fileName='Перевозчики'
            data={searchData.map((item, index) => ({
              ...item,
              number: index + 1
            }))}
          />
        </div>
      </div>
      <div>
        <Spin
          tip={t('intersections.loadingPleaseWait') + '...'}
          spinning={isLoading}
        >
          <Table
            sticky
            size='middle'
            columns={columns}
            scroll={{ x: 1500 }}
            dataSource={searchData.map((item, index) => ({
              ...item,
              number: index + 1
            }))} />
        </Spin>
      </div>
      <ModalTransporter
        isModal={isModal}
        setIsModal={setIsModal}
        initialFormValues={initialFormValues}
        requestGetTransporters={requestGetTransporters}
        setInitialFormValues={setInitialFormValues}
        request={request}
      />
    </>
  );
};
