import {
  Divider,
  Input,
  Button,
  message,
  Popconfirm,
  Spin,
  Table,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ROOT } from '../../../../../config';
import { getNetwork } from '../../../../../utils/getNetwork';
import { ServiceForm } from '../../forms/ServiceForm';

const { Search } = Input;

export const ListService = ({ isAction }) => {
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);

  const [modalState, setModalState] = useState({
    show: false,
    editData: {},
  });
  const { t } = useTranslation();

  const getData = () => {
    getNetwork().get(API_ROOT + '/reestruslug/getAll').then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
        setSearchData(res.data);
      }
      setLoading(false);
    });
  };

  const confirmDelete = (id) => {
    getNetwork().delete(API_ROOT + '/reestruslug/' + id).then((res) => {
      if (res.status === 200) {
        message.success('Успешно удален!');
        getData();
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: 'Код услуги',
      dataIndex: 'serviceCode',
      width: 120,
      key: 'serviceCode',
    },
    {
      title: 'Наименование государственных услуг',
      dataIndex: 'nameOfService',
      key: 'nameOfService',
    },
    (isAction && (
      {
        title: t('intersections.action'),
        width: 150,
        key: 'action',
        render: (_, record) => (
          <span>
            <span
              className="link"
              onClick={() =>
                setModalState({ show: true, editData: record })
              }
            >
              {t('intersections.edit')}
            </span>
            <Divider type="vertical" />
            <Popconfirm
              title={t('intersections.exactlyWantToDelete') + '?'}
              onConfirm={() => confirmDelete(record.serviceDirectoryId)}
              onCancel={() => { }}
              okText="Да"
              cancelText="Нет"
            >
              <a href="#">{t('intersections.delete')}</a>
            </Popconfirm>
          </span>
        ),
      }
    ))
  ].filter(Boolean);

  const handleMenuClick = () => {
    setModalState({ show: true });
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
        <Button onClick={handleMenuClick}>Создать</Button>
        <Search
          defaultValue={search}
          style={{ marginLeft: 20, width: 400 }}
          placeholder="Поиск"
          onSearch={(value) => {
            setSearch(value);
            setSearchData(
              data.filter((i) => {
                if (i.nameOfService && i.serviceCode)
                  return (
                    i.nameOfService.toLowerCase().includes(value.toLowerCase()) ||
                    i.serviceCode.toLowerCase().includes(value.toLowerCase())
                  );
                else return false;
              })
            );
          }}
          enterButton
        />
      </div>
      <Spin
        tip={t('intersections.loadingPleaseWait') + '...'}
        spinning={loading}
      >
        <Table
          rowKey={(record) => record.serviceDirectoryId}
          columns={columns}
          dataSource={searchData}
          bordered
        />
      </Spin>
      <Modal
        title="Новая услуга"
        visible={modalState.show}
        onOk={() => setModalState({ show: true })}
        onCancel={() => setModalState({ show: false })}
        footer={false}
      >
        <ServiceForm
          editData={modalState.editData}
          onCancel={() => setModalState({ show: false })}
        />
      </Modal>
    </>
  );
};