import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import BasicLayout from '../../components/layout/BasicLayout';
import { Chart } from './components/tabs/Chart/Chart';
import { ChartBuilder } from './components/tabs/ChartBuilder/ChartBuilder';
import { ListService } from './components/tabs/ListService/ListService';
import { ListEvent } from './components/tabs/ListEvent/ListEvent';
import { checkRole } from '../../utils/CheckRoleWithToken';
import { ChartStatus } from './components/tabs/ChartStatus/ChartStatus';
import { useTranslation } from 'react-i18next';

const roles = ['all', 'akm'];
const { TabPane } = Tabs;

export const GosServiceList = () => {
  const [tab, setTab] = useState(sessionStorage.getItem('gos-service-tab') || '1');
  const [isAction, setIsAction] = useState(false);
  const { t } = useTranslation();

  const handleChangeTabs = (key) => {
    sessionStorage.setItem('gos-service-tab', key);
    setTab(key);
  };

  useEffect(() => {
    const roleList = checkRole();

    setIsAction(roleList.some((role) => roles.includes(role)));
  }, []);

  const ListTabs = () => (
    <Tabs className="gosuslugy" defaultActiveKey={tab} onChange={handleChangeTabs}>
      <TabPane tab={t('gosServiceList.serviceList')} key="1">
        <ListService isAction={isAction} />
      </TabPane>
      <TabPane tab={t('gosServiceList.eventList')} key="2">
        <ListEvent isAction={isAction} />
      </TabPane>
      <TabPane tab={t('gosServiceList.charts')} key="3">
        <Chart isAction={isAction} />
      </TabPane>
      <TabPane tab={t('gosServiceList.createChart')} key="4">
        <ChartBuilder />
      </TabPane>
      <TabPane tab={t('gosServiceList.chartStatus')} key="5">
        <ChartStatus isAction={isAction} />
      </TabPane>
    </Tabs>
  );

  return (
    <BasicLayout>
      <ListTabs />
    </BasicLayout>
  );
};
