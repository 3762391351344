import React, { Component } from 'react';
import BasicLayout from '../components/layout/BasicLayout';
import { API_ROOT } from '../config';
import {
  Row,
  Col,
  Table,
  Popconfirm,
  message,
  Popover,
  Input,
  Button,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { getNetwork } from '../utils/getNetwork';
const Search = Input.Search;

class CatalogsPage extends Component {
  constructor() {
    super();
    this.state = {
      categories: [],
      builders: [],
      eventNames: [],
      showAdd: false,
      showAddB: false,
      showEvent: false,
    };
    this.getData = this.getData.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.handleShowAdd = this.handleShowAdd.bind(this);
    this.handeShowEventName = this.handeShowEventName.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    getNetwork().post(API_ROOT + '/api/v1/roadway/workcategory/all').then((r) => {
      let categories = r.data;
      this.setState({ categories });
    });
    getNetwork().post(API_ROOT + '/api/v1/roadway/contractor/all').then((r) => {
      let builders = r.data;
      this.setState({ builders });
    });
    getNetwork().post(API_ROOT + '/api/v1/roadway/eventname/all').then((r) => {
      let eventNames = r.data;
      this.setState({ eventNames });
    });
  }

  confirmDelete(id, type) {
    let { t } = this.props;
    let api = '/api/v1/roadway/workcategory/remove/';
    if (type === 'builder') {
      api = '/api/v1/roadway/contractor/remove/';
    } else if (type === 'eventName') {
      api = '/api/v1/roadway/eventname/remove/';
    }
    getNetwork().post(API_ROOT + api + id).then(() => {
      message.success(t('settingUpDirectories.removed'));
      this.getData();
    });
  }

  handleSearchChange(val) {
    this.setState({ showAdd: val });
  }

  handleShowAdd(val) {
    this.setState({ showAddB: val });
  }

  handeShowEventName(val) {
    this.setState({ showEvent: val });
  }

  addCategory(val) {
    let { t } = this.props;
    let api = '/api/v1/roadway/workcategory/save';
    getNetwork().post(API_ROOT + api + '?nameRu=' + val).then(() => {
      message.success(t('settingUpDirectories.addedBy'));
      this.getData();
      this.setState({ showAdd: false });
    });
  }
  addBuilder(val) {
    let { t } = this.props;
    let api = '/api/v1/roadway/contractor/save';
    getNetwork().post(API_ROOT + api + '?nameRu=' + val).then(() => {
      message.success(t('settingUpDirectories.addedBy'));
      this.getData();
      this.setState({ showAddB: false });
    });
  }

  addEventName(val) {
    let { t } = this.props;
    let api = '/api/v1/roadway/eventname/save';
    getNetwork().post(API_ROOT + api + '?nameRu=' + val).then(() => {
      message.success(t('settingUpDirectories.addedBy'));
      this.getData();
      this.setState({ showEvent: false });
    });
  }

  render() {
    let { t } = this.props;
    const columnsCat = [
      {
        title: t('settingUpDirectories.name'),
        dataIndex: 'nameRu',
        key: 'nameRu',
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            <Popconfirm
              title={t('settingUpDirectories.exactlyWantToDelete') + '?'}
              onConfirm={() => this.confirmDelete(record.id, 'category')}
              okText={t('settingUpDirectories.yes')}
              cancelText={t('settingUpDirectories.no')}
            >
              <a href="javascript:void(0)">
                {t('settingUpDirectories.delete')}
              </a>
            </Popconfirm>
          </span>
        ),
      },
    ];
    const columnsBuilder = [
      {
        title: t('settingUpDirectories.name'),
        dataIndex: 'nameRu',
        key: 'nameRu',
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            <Popconfirm
              title={t('settingUpDirectories.exactlyWantToDelete') + '?'}
              onConfirm={() => this.confirmDelete(record.id, 'builder')}
              okText="Да"
              cancelText="Нет"
            >
              <a href="javascript:void(0)">
                {t('settingUpDirectories.delete')}
              </a>
            </Popconfirm>
          </span>
        ),
      },
    ];
    const columnsEventname = [
      {
        title: t('settingUpDirectories.name'),
        dataIndex: 'nameRu',
        key: 'nameRu',
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            <Popconfirm
              title={t('settingUpDirectories.exactlyWantToDelete') + '?'}
              onConfirm={() => this.confirmDelete(record.id, 'eventName')}
              okText={t('settingUpDirectories.yes')}
              cancelText={t('settingUpDirectories.no')}
            >
              <a href="javascript:void(0)">
                {t('settingUpDirectories.delete')}
              </a>
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <BasicLayout>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <h4>{t('settingUpDirectories.categoryReference')}</h4>
            <Table
              rowKey={(record) => record.id}
              columns={columnsCat}
              dataSource={this.state.categories}
              title={() => {
                return (
                  <Popover
                    placement="right"
                    content={
                      <Search
                        placeholder={t('settingUpDirectories.enterNewCategory')}
                        onSearch={(value) => this.addCategory(value)}
                        enterButton={t('settingUpDirectories.addTo')}
                      />
                    }
                    trigger="click"
                    visible={this.state.showAdd}
                    onVisibleChange={this.handleSearchChange}
                  >
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => this.handleSearchChange(true)}
                    />
                  </Popover>
                );
              }}
            />
          </Col>
          <Col className="gutter-row" span={12}>
            <h4>{t('settingUpDirectories.contractorsDirectory')}</h4>
            <Table
              rowKey={(record) => record.id}
              columns={columnsBuilder}
              dataSource={this.state.builders}
              title={() => {
                return (
                  <Popover
                    placement="right"
                    content={
                      <Search
                        placeholder={t('settingUpDirectories.enterNewCategory')}
                        onSearch={(value) => this.addBuilder(value)}
                        enterButton={t('settingUpDirectories.plus')}
                      />
                    }
                    trigger="click"
                    visible={this.state.showAddB}
                    onVisibleChange={this.handleShowAdd}
                  >
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => this.handleShowAdd(true)}
                    />
                  </Popover>
                );
              }}
            />
          </Col>
          <Col className="gutter-row" span={12}>
            <h4>{t('settingUpDirectories.responsibleDirectory')}</h4>
            <Table
              rowKey={(record) => record.id}
              columns={columnsEventname}
              dataSource={this.state.eventNames}
              title={() => {
                return (
                  <Popover
                    placement="right"
                    content={
                      <Search
                        placeholder={t('settingUpDirectories.enterResponsible')}
                        onSearch={(value) => this.addEventName(value)}
                        enterButton={t('settingUpDirectories.addTo')}
                      />
                    }
                    trigger="click"
                    visible={this.state.showEvent}
                    onVisibleChange={this.handeShowEventName}
                  >
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => this.handeShowEventName(true)}
                    />
                  </Popover>
                );
              }}
            />
          </Col>
        </Row>
      </BasicLayout>
    );
  }
}
export default withTranslation()(CatalogsPage);
