export const EDITABLE_COLUMNS = {
  1: [
    'serviceCountByStateorgPeopleFiz',
    'serviceCountByStateorgPeopleYr',
    'serviceCountByStateorgCompaniesFiz',
    'serviceCountByStateorgCompaniesYr',
    'inPeperFormFiz',
    'inPeperFormYr',
    'electronicalyThroughtInformationSystemsFiz',
    'electronicalyThroughtInformationSystemsYr',
    'throughTheServiceProviderFiz',
    'throughTheServiceProviderYr',
  ],
  2: [
    'justifiedElectronicFiz',
    'justifiedElectronicYr',
    'justifiedInPaperFromFiz',
    'justifiedInPaperFromYr',
    'unsubstantiatedElectronicFiz',
    'unsubstantiatedElectronicYr',
    'unsubstantiatedInPaperFromFiz',
    'unsubstantiatedInPaperFromYr',
  ],
  3: ['count'],
};

export const EDITABLE_LIST_SERVICE_CODES = ['2302002'];