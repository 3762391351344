import { Button, Select, Spin, Table, Form, message, InputNumber } from 'antd';
import { getNetwork } from '../../../../../utils/getNetwork';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ROOT } from '../../../../../config';
import styles from './ChartBuilder.module.scss';

import { chain } from 'lodash';
import { combineSum, groupData } from '../../../utils';
import { summaryTable } from '../../../utils/SummaryTable';
import { EDITABLE_COLUMNS, EDITABLE_LIST_SERVICE_CODES } from './constants';

export const ChartBuilder = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [reports, setReports] = useState(JSON.parse(localStorage.getItem('reportsStash')) || []);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(
    JSON.parse(localStorage.getItem('formCreateReport')) || {
      chartType: null,
      org: null,
      year: null,
      month: null,
      district: null,
    }
  );
  const [formData, setFormData] = useState({
    chartTypes: [],
    orgs: [],
    years: [],
    months: [],
    district: [],
  });

  const validateField = useCallback(
    (id, value) => {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (value === null) {
          newErrors[id] = t('intersections.obligatoryField');
        } else {
          delete newErrors[id];
        }
        return newErrors;
      });
    },
    [t]
  );

  const getSumValue = (elem, name) =>
    EDITABLE_COLUMNS[formValues.chartType].reduce((acc, cur) => {
      if (cur.includes(name)) {
        acc += elem[cur] || 0;
      }
      return acc;
    }, 0);

  const updateData = (elem) => {
    return {
      ...elem,
      summaryFiz: getSumValue(elem, 'Fiz'),
      summaryYr: getSumValue(elem, 'Yr'),
    };
  };

  const handleChangeInputNumber = (fieldTitle, elem, fieldId) => (value) => {
    const updatedField = { ...elem, [fieldTitle]: value };
    if (formValues.chartType === 3) {
      setReports((prev) =>
        prev.map((item) => {
          if (item.subcategoryId === elem.subcategoryId) {
            return {
              ...item,
              count: value,
            };
          }
          return item;
        })
      );
    } else {
      setReports((prev) =>
        prev.map((item) => {
          if (item.serviceCode === updatedField.serviceCode) {
            return updateData(updatedField);
          }
          return item;
        })
      );
    }
    validateField(fieldId, value);
  };

  const checkModifyElement = (name, isEdit) => {
    const fieldsYr = EDITABLE_COLUMNS[formValues.chartType].filter((title) => title.includes('Yr'));
    if (fieldsYr.includes(name) && !isEdit) {
      return true;
    }
    return false;
  };

  const getTotalField = (name) =>
    reports.reduce((acc, cur) => {
      if (cur[name]) {
        acc += cur[name];
      }
      return acc;
    }, 0);

  const generateFieldForm = (name, elem) => {
    if (elem.nameOfService === 'Итого') {
      return <span>{getTotalField(name, elem)}</span>;
    }
    const fieldId = `${formValues.chartType === 3 ? elem.key : elem.serviceCode}__${name}`;
    return (
      <>
        <InputNumber
          className={errors[fieldId] && styles['input-error']}
          min={0}
          style={{ border: 'none' }}
          disabled={
            !EDITABLE_LIST_SERVICE_CODES.includes(elem.serviceCode) &&
            checkModifyElement(name, elem.isAvailableEdit)
          }
          defaultValue={elem[name] || 0}
          name={fieldId}
          onChange={handleChangeInputNumber(name, elem, fieldId)}
        />
        {errors[fieldId] && <div className={styles.error}>{errors[fieldId]}</div>}
      </>
    );
  };

  const COLUMNS = {
    1: [
      {
        title: 'Код услуги',
        dataIndex: 'serviceCode',
        key: 'serviceCode',
      },
      {
        title: 'Наименование государственных услуг',
        dataIndex: 'nameOfService',
        key: 'nameOfService',
        width: 200,
      },
      {
        title: 'ВСЕГО (количество оказанных госуслуг)',
        dataIndex: 'summary',
        key: 'summary',
        rowSpan: 3,
        children: [
          {
            children: [
              {
                children: [
                  {
                    title: 'физ. лица',
                    dataIndex: 'summaryFiz',
                    key: 'summaryFiz',
                    align: 'center',
                    className: 'fizColumn summaryColumn',
                    render: (text, record) => {
                      if (record.nameOfService === 'Итого') {
                        return <span>{getTotalField('summaryFiz')}</span>;
                      }
                      return <span>{text}</span>;
                    },
                  },
                  {
                    title: 'юр. лица',
                    dataIndex: 'summaryYr',
                    align: 'center',
                    key: 'summaryYr',
                    className: 'yrColumn summaryColumn',
                    render: (text, record) => {
                      if (record.nameOfService === 'Итого') {
                        return <span>{getTotalField('summaryYr')}</span>;
                      }
                      return <span>{text}</span>;
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'непосредственно оказанных через:',
        children: [
          {
            title: 'Государственная корпорация «Правительство для граждан»',
            rowSpan: 2,
            children: [
              {
                children: [
                  {
                    title: 'физ. лица',
                    dataIndex: 'serviceCountByStateorgPeopleFiz',
                    key: 'serviceCountByStateorgPeopleFiz',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('serviceCountByStateorgPeopleFiz', record),
                  },
                  {
                    title: 'юр. лица',
                    dataIndex: 'serviceCountByStateorgPeopleYr',
                    key: 'serviceCountByStateorgPeopleYr',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('serviceCountByStateorgPeopleYr', record),
                  },
                ],
              },
            ],
          },
          {
            title: 'Портал электронного правительство',
            rowSpan: 2,
            children: [
              {
                children: [
                  {
                    title: 'физ. лица',
                    dataIndex: 'serviceCountByStateorgCompaniesFiz',
                    key: 'serviceCountByStateorgCompaniesFiz',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('serviceCountByStateorgCompaniesFiz', record),
                  },
                  {
                    title: 'юр. лица',
                    dataIndex: 'serviceCountByStateorgCompaniesYr',
                    key: 'serviceCountByStateorgCompaniesYr',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('serviceCountByStateorgCompaniesYr', record),
                  },
                ],
              },
            ],
          },
          {
            title: 'государственный орган:',
            children: [
              {
                title: 'В бумажной форме',
                children: [
                  {
                    title: 'физ. лица',
                    dataIndex: 'inPeperFormFiz',
                    key: 'inPeperFormFiz',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) => generateFieldForm('inPeperFormFiz', record),
                  },
                  {
                    title: 'юр. лица',
                    dataIndex: 'inPeperFormYr',
                    key: 'inPeperFormYr',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) => generateFieldForm('inPeperFormYr', record),
                  },
                ],
              },
              {
                title:
                  // eslint-disable-next-line max-len
                  'электронный вид через информационные системы услугодателя без прямого контакта с услугополучателем (за исключением www.egov.kz, www.elicense.kz)',
                children: [
                  {
                    title: 'физ. лица',
                    dataIndex: 'electronicalyThroughtInformationSystemsFiz',
                    key: 'electronicalyThroughtInformationSystemsFiz',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('electronicalyThroughtInformationSystemsFiz', record),
                  },
                  {
                    title: 'юр. лица',
                    dataIndex: 'electronicalyThroughtInformationSystemsYr',
                    key: 'electronicalyThroughtInformationSystemsYr',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('electronicalyThroughtInformationSystemsYr', record),
                  },
                ],
              },
              {
                title:
                  // eslint-disable-next-line max-len
                  'электронный вид через информационные системы услугодателя путем прямого контакта с услугополучателем и ручного ввода заявки в информационную систему (за исключением www.egov.kz, www.elicense.kz)',
                children: [
                  {
                    title: 'физ. лица',
                    dataIndex: 'throughTheServiceProviderFiz',
                    key: 'throughTheServiceProviderFiz',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('throughTheServiceProviderFiz', record),
                  },
                  {
                    title: 'юр. лица',
                    dataIndex: 'throughTheServiceProviderYr',
                    key: 'throughTheServiceProviderYr',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) => generateFieldForm('throughTheServiceProviderYr', record),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    2: [
      {
        title: 'Код услуги',
        dataIndex: 'serviceCode',
        key: 'serviceCode',
      },
      {
        title: 'Наименование государственных услуг',
        dataIndex: 'nameOfService',
        key: 'nameOfService',
        width: 200,
      },
      {
        title: 'Количество обоснованных',
        children: [
          {
            title: 'Выданных в электроннном виде',
            children: [
              {
                title: 'физ. лица',
                dataIndex: 'justifiedElectronicFiz',
                key: 'justifiedElectronicFiz',
                className: 'fizColumn',
                render: (_, record) => generateFieldForm('justifiedElectronicFiz', record),
              },
              {
                title: 'юр. лица',
                dataIndex: 'justifiedElectronicYr',
                key: 'justifiedElectronicYr',
                className: 'yrColumn',
                render: (_, record) => generateFieldForm('justifiedElectronicYr', record),
              },
            ],
          },
          {
            title: 'Выданных в бумажном виде',
            children: [
              {
                title: 'физ. лица',
                dataIndex: 'justifiedInPaperFromFiz',
                key: 'justifiedInPaperFromFiz',
                className: 'fizColumn',
                render: (_, record) => generateFieldForm('justifiedInPaperFromFiz', record),
              },
              {
                title: 'юр. лица',
                dataIndex: 'justifiedInPaperFromYr',
                key: 'justifiedInPaperFromYr',
                className: 'yrColumn',
                render: (_, record) => generateFieldForm('justifiedInPaperFromYr', record),
              },
            ],
          },
        ],
      },
      {
        title: 'Количество необоснованных',
        children: [
          {
            title: 'Выданных в электроннном виде',
            children: [
              {
                title: 'физ. лица',
                dataIndex: 'unsubstantiatedElectronicFiz',
                key: 'unsubstantiatedElectronicFiz',
                className: 'fizColumn',
                render: (_, record) => generateFieldForm('unsubstantiatedElectronicFiz', record),
              },
              {
                title: 'юр. лица',
                dataIndex: 'unsubstantiatedElectronicYr',
                key: 'unsubstantiatedElectronicYr',
                className: 'yrColumn',
                render: (_, record) => generateFieldForm('unsubstantiatedElectronicYr', record),
              },
            ],
          },
          {
            title: 'Выданных в бумажном виде',
            children: [
              {
                title: 'физ. лица',
                dataIndex: 'unsubstantiatedInPaperFromFiz',
                key: 'unsubstantiatedInPaperFromFiz',
                className: 'fizColumn',
                render: (_, record) => generateFieldForm('unsubstantiatedInPaperFromFiz', record),
              },
              {
                title: 'юр. лица',
                dataIndex: 'unsubstantiatedInPaperFromYr',
                key: 'unsubstantiatedInPaperFromYr',
                className: 'yrColumn',
                render: (_, record) => generateFieldForm('unsubstantiatedInPaperFromYr', record),
              },
            ],
          },
        ],
      },
    ],
    3: [
      {
        title: '№ п/п',
        dataIndex: 'number',
        key: 'number',
        width: 110,
      },
      {
        title: 'Выполняемые мероприятия',
        dataIndex: 'subcategory',
        key: 'subcategory',
        width: 500,
      },
      {
        title: 'Количество',
        dataIndex: 'count',
        key: 'count',
        align: 'center',
        width: 110,
        render: (_, record) =>
          Array.isArray(record.children) && record.children.length
            ? record.count
            : generateFieldForm('count', record),
      },
    ],
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    localStorage.setItem('reportsStash', JSON.stringify(reports));
  }, [reports]);

  const getData = useCallback(() => {
    Promise.all([
      getNetwork().get(API_ROOT + '/reestruslug/get/organization/id'),
      getNetwork().get(API_ROOT + '/myReport/getTitle'),
      getNetwork().get(API_ROOT + '/myReport/getYear'),
      getNetwork().get(API_ROOT + '/myReport/getMonth'),
      getNetwork().get(API_ROOT + '/myReport/getDistrict'),
    ])
      .then((resArr = []) => resArr.map((i) => i.data))
      .then(([orgs, chartTypes, years, months, district]) =>
        setFormData((prev) => ({ ...prev, orgs, chartTypes, years, months, district }))
      );
  }, []);

  const getOrganizations = (district) => {
    getNetwork()
      .get(API_ROOT + `/reestruslug/get/organization/id?district=${district}`)
      .then((res) => {
        setFormData({ ...formData, orgs: res.data });
      });
  };

  const getChartData = async () => {
    setLoading(true);
    Promise.all([
      getNetwork().get(
        API_ROOT +
          `/createReport/get/form${formValues.chartType}?district=${
            formValues.district || ''
          }&organizationId=${formValues.org || ''}&yearId=${formValues.year || ''}&monthId=${
            formValues.month || ''
          }`
      ),
      getNetwork().get(API_ROOT + '/createReport/get/chapter'),
      getNetwork().get(API_ROOT + '/createReport/get/category'),
    ]).then(([tableItems, chapterIdList, categoryIdList]) => {
      if (tableItems && tableItems.status === 200) {
        const result = tableItems.data.map((item) => {
          if (chapterIdList) {
            const chapterId = chapterIdList.data.find(
              (chapter) => chapter.chapter === item.chapter
            );
            if (chapterId) {
              item.chapterId = chapterId.id;
            }
          }
          if (categoryIdList) {
            const categoryId = categoryIdList.data.find(
              (category) => category.category === item.category
            );
            if (categoryId) {
              item.categoryId = categoryId.id;
            }
          }
          return {
            ...item,
            summaryFiz: 0,
            summaryYr: 0,
          };
        });

        setReports(combineSum(result, formValues.chartType));
      }

      const monthResult = formData.months.find((monthObj) => monthObj.id === formValues.month);
      const yearResult = formData.years.find((yearObj) => yearObj.id === formValues.year);
      const organizationResult = formData.orgs.find((orgObj) => orgObj.id === formValues.org);
      if (tableItems.status === 409) {
        message.error(`
                          Отчет от ${organizationResult ? organizationResult.organization : ''} ${
                            monthResult && yearResult
                              ? 'за ' + monthResult.month + ' ' + yearResult.year
                              : ''
                          } уже существует, в случае необходимости
                          внести изменения, просим вас сделать редактирование уже существующего отчета
                      `);
      } else if (tableItems.status === 403) {
        message.error('Невозможно создать отчет за месяцы, которые еще не наступили!');
      }
    });
    setLoading(false);
  };

  const handleChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const requestCreateReport = async (body) => {
    const result = await getNetwork().post(
      API_ROOT + `/createReport/create/report${formValues.chartType}`,
      body
    );

    if (result.status === 200) {
      message.success('Отчет успешно создан!');
      reset();
    }
  };

  const getCountValue = (arr, name, elem) => {
    const result = arr.find((value) => value[name] === elem[name]);
    if (result && result.value) {
      return result.value;
    }
    return null;
  };

  const handleClickSave = async () => {
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      message.error(t('error.pleaseFillInTheHighlightedFields'));
      return;
    }
    const chapterGroups = chain(reports)
      .groupBy('chapterId')
      .map((value) => value)
      .value();
    const chapterResult = chapterGroups
      .filter((arr) => arr[0].subcategoryId)
      .map((array) => {
        const sum = array.reduce((sum, cur) => (sum += cur.count), 0);
        return {
          chapterId: array[0].chapterId,
          value: sum,
        };
      });
    const categoryGroups = chain(reports)
      .groupBy('categoryId')
      .map((value) => value)
      .value();
    const categoryResult = categoryGroups
      .filter((arr) => arr[0].subcategoryId)
      .map((array) => {
        const sum = array.reduce((sum, cur) => (sum += cur.count), 0);
        return {
          categoryId: array[0].categoryId,
          value: sum,
        };
      });

    const newData = reports.reduce((acc, cur) => {
      if (cur.nameOfService === 'Итого') {
        return acc;
      }

      const result =
        formValues.chartType === 3
          ? {
              subcategoryId: cur.subcategoryId,
              categoryId: cur.categoryId,
              chapterId: cur.chapterId,
              organizationsOfAkmolaRegionId: formValues.org,
              monthOfFillingId: formValues.month,
              yearOfFillingId: formValues.year,
              count: cur.count,
              categoryCount: getCountValue(categoryResult, 'categoryId', cur),
              chapterCount: getCountValue(chapterResult, 'chapterId', cur),
            }
          : {
              ...cur,
              organizationsOfAkmolaRegionId: formValues.org,
              yearOfFillingId: formValues.year,
              monthOfFillingId: formValues.month,
            };

      return acc.concat(result);
    }, []);

    requestCreateReport(newData);
    setLoading(false);
  };

  const handleChangeChartType = (value) => {
    setReports([]);
    setFormValues((prev) => ({ ...prev, chartType: value }));
  };

  const handleChangeOrg = (value) => {
    setReports([]);
    setFormValues((prev) => ({
      ...prev,
      org: value,
    }));
  };

  const handleChangeDistrict = (value) => {
    setReports([]);
    setFormValues((prev) => ({
      ...prev,
      district: value,
    }));
    getOrganizations(value);
  };

  const reset = () => {
    setReports([]);
    setFormValues({
      chartType: null,
      org: null,
      year: null,
      month: null,
      district: null,
    });
  };

  const generateTitleFile = (part, title, organizationResult, monthResult, yearResult) => {
    return title
      .replace(
        part,
        `${part} ${organizationResult ? organizationResult.organization : ''} ${
          monthResult && yearResult ? 'за ' + monthResult.month + ' ' + yearResult.year : ''
        }`
      )
      .replace(/[.,/#!$%^&*;:{}=\-_`~()]$/g, '');
  };

  const createTitleFile = (title) => {
    const organizationResult = formData.orgs.find((orgObj) => orgObj.id === formValues.org);
    const monthResult = formData.months.find((monthObj) => monthObj.id === formValues.month);
    const yearResult = formData.years.find((yearObj) => yearObj.id === formValues.year);

    if (title.includes('Отчет о работе')) {
      return generateTitleFile(
        'Отчет о работе',
        title,
        organizationResult,
        monthResult,
        yearResult
      );
    }

    if (title.includes('Отчет о выполнении')) {
      return generateTitleFile(
        'Отчет о выполнении',
        title,
        organizationResult,
        monthResult,
        yearResult
      );
    }

    return title;
  };

  useEffect(() => {
    if (formValues.chartType === 1) {
      const listTh = document.querySelectorAll('th');
      listTh.forEach((th) => {
        if (!th.innerHTML) {
          th.remove();
        }
      });
    }
    localStorage.setItem('formCreateReport', JSON.stringify(formValues));
  }, [formValues]);

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: 20, maxWidth: '100%' }}>
        <Select
          style={{ width: 300, marginRight: 15 }}
          placeholder="Наименование отчёта"
          value={formValues.chartType}
          onChange={handleChangeChartType}
        >
          {formData.chartTypes.map((e) => (
            <Select.Option key={e.id} value={e.id}>
              {e.reportTitle}
            </Select.Option>
          ))}
        </Select>
        <Select
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{ width: 200, marginRight: 15 }}
          placeholder="Район"
          value={formValues.district}
          onChange={handleChangeDistrict}
        >
          {formData.district.map((el) => (
            <Select.Option key={el} value={el}>
              {el}
            </Select.Option>
          ))}
        </Select>
        <Select
          style={{ width: 200, marginRight: 15 }}
          placeholder="Наименование организации"
          value={formValues.org}
          onChange={handleChangeOrg}
        >
          {formData.orgs.map((e) => (
            <Select.Option key={e.id} value={e.id}>
              {e.organization}
            </Select.Option>
          ))}
        </Select>

        <Select
          style={{ width: 100, marginRight: 15 }}
          placeholder="Год"
          value={formValues.year}
          onChange={(val) => {
            setReports([]);
            handleChange('year', val);
          }}
        >
          {formData.years.map((el) => (
            <Select.Option key={el.id} value={el.id}>
              {el.year}
            </Select.Option>
          ))}
        </Select>
        <Select
          style={{ width: 100, marginRight: 15 }}
          placeholder="Месяц"
          value={formValues.month}
          onChange={(val) => {
            setReports([]);
            handleChange('month', val);
          }}
        >
          {formData.months.map((el) => (
            <Select.Option key={el.id} value={el.id}>
              {el.month}
            </Select.Option>
          ))}
        </Select>
        <Button
          type="primary"
          onClick={getChartData}
          style={{ marginRight: 15 }}
          disabled={!formValues.chartType}
        >
          Создать
        </Button>
        <Button onClick={reset}>Сбросить</Button>
      </div>
      {formData.chartTypes.filter((i) => i.id === formValues.chartType)[0] && (
        <h3>
          {createTitleFile(
            formData.chartTypes.filter((i) => i.id === formValues.chartType)[0].reportTitle
          )}
        </h3>
      )}
      <Spin tip={t('intersections.loadingPleaseWait') + '...'} spinning={loading}>
        <Table
          rowKey={(record) => (formValues.chartType === 3 ? record.key : record.serviceCode)}
          columns={COLUMNS[formValues.chartType]}
          dataSource={formValues.chartType === 3 ? groupData(reports) : reports}
          bordered
          scroll={{
            x: formValues.chartType === 1 ? 1700 : null,
            y: window.innerHeight - 100,
          }}
          className={`table-${formValues.chartType} ${styles.table}`}
          summary={formValues.chartType === 3 ? summaryTable(reports) : null}
        />
        <Button
          type="primary"
          onClick={handleClickSave}
          style={{ marginRight: 15, marginTop: 16 }}
          disabled={!formValues.chartType}
        >
          Сохранить
        </Button>
      </Spin>
    </div>
  );
};
