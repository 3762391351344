export const COLUMNS = {
  1: [
    {
      title: 'Код услуги',
      dataIndex: 'serviceCode',
      key: 'serviceCode',
      align: 'center',
    },
    {
      title: 'Наименование государственных услуг',
      dataIndex: 'nameOfService',
      key: 'nameOfService',
      align: 'center',
      width: 200,
    },
    {
      title: 'ВСЕГО (количество оказанных госуслуг)',
      dataIndex: 'summary',
      key: 'summary',
      align: 'center',
      rowSpan: 3,
      children: [
        {
          children: [
            {
              children: [
                {
                  title: 'физ. лица',
                  dataIndex: 'summaryFiz',
                  key: 'summaryFiz',
                  align: 'center',
                  className: 'fizColumn summaryColumn',
                  render: (_, record) => record.summaryFiz || 0
                },
                {
                  title: 'юр. лица',
                  dataIndex: 'summaryYr',
                  key: 'summaryYr',
                  align: 'center',
                  className: 'yrColumn summaryColumn',
                  render: (_, record) => record.summaryYr || 0
                },
              ]
            }
          ]
        }
      ],
    },
    {
      title: 'непосредственно оказанных через:',
      children: [
        {
          title: 'Государственная корпорация «Правительство для граждан»',
          rowSpan: 2,
          children: [
            {
              children: [
                {
                  title: 'физ. лица',
                  dataIndex: 'serviceCountByStateorgPeopleFiz',
                  key: 'serviceCountByStateorgPeopleFiz',
                  align: 'center',
                  className: 'fizColumn',
                  render: (_, record) => record.serviceCountByStateorgPeopleFiz || 0
                },
                {
                  title: 'юр. лица',
                  dataIndex: 'serviceCountByStateorgPeopleYr',
                  key: 'serviceCountByStateorgPeopleYr',
                  align: 'center',
                  className: 'yrColumn',
                  render: (_, record) => record.serviceCountByStateorgPeopleYr || 0
                },
              ]
            }
          ],
        },
        {
          title: 'Портал электронного правительство',
          rowSpan: 2,
          children: [
            {
              children: [
                {
                  title: 'физ. лица',
                  dataIndex: 'serviceCountByStateorgCompaniesFiz',
                  key: 'serviceCountByStateorgCompaniesFiz',
                  align: 'center',
                  className: 'fizColumn',
                  render: (_, record) => record.serviceCountByStateorgCompaniesFiz || 0
                },
                {
                  title: 'юр. лица',
                  dataIndex: 'serviceCountByStateorgCompaniesYr',
                  key: 'serviceCountByStateorgCompaniesYr',
                  align: 'center',
                  className: 'yrColumn',
                  render: (_, record) => record.serviceCountByStateorgCompaniesYr || 0
                },
              ]
            }
          ],
        },
        {
          title: 'государственный орган:',
          children: [
            {
              title: 'В бумажной форме',
              children: [
                {
                  title: 'физ. лица',
                  dataIndex: 'inPeperFormFiz',
                  key: 'inPeperFormFiz',
                  align: 'center',
                  className: 'fizColumn',
                  render: (_, record) => record.inPeperFormFiz || 0
                },
                {
                  title: 'юр. лица',
                  dataIndex: 'inPeperFormYr',
                  key: 'inPeperFormYr',
                  align: 'center',
                  className: 'yrColumn',
                  render: (_, record) => record.inPeperFormYr || 0
                },
              ],
            },
            {
              // eslint-disable-next-line max-len
              title: 'электронный вид через информационные системы услугодателя без прямого контакта с услугополучателем (за исключением www.egov.kz, www.elicense.kz)',
              children: [
                {
                  title: 'физ. лица',
                  dataIndex: 'electronicalyThroughtInformationSystemsFiz',
                  key: 'electronicalyThroughtInformationSystemsFiz',
                  align: 'center',
                  className: 'fizColumn',
                  render: (_, record) => record.electronicalyThroughtInformationSystemsFiz || 0
                },
                {
                  title: 'юр. лица',
                  dataIndex: 'electronicalyThroughtInformationSystemsYr',
                  key: 'electronicalyThroughtInformationSystemsYr',
                  align: 'center',
                  className: 'yrColumn',
                  render: (_, record) => record.electronicalyThroughtInformationSystemsYr || 0
                },
              ],
            },
            {
              // eslint-disable-next-line max-len
              title: 'электронный вид через информационные системы услугодателя путем прямого контакта с услугополучателем и ручного ввода заявки в информационную систему (за исключением www.egov.kz, www.elicense.kz)',
              children: [
                {
                  title: 'физ. лица',
                  dataIndex: 'throughTheServiceProviderFiz',
                  key: 'throughTheServiceProviderFiz',
                  align: 'center',
                  className: 'fizColumn',
                  render: (_, record) => record.throughTheServiceProviderFiz || 0
                },
                {
                  title: 'юр. лица',
                  dataIndex: 'throughTheServiceProviderYr',
                  key: 'throughTheServiceProviderYr',
                  align: 'center',
                  className: 'yrColumn',
                  render: (_, record) => record.throughTheServiceProviderYr || 0
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  2: [
    {
      title: 'Код услуги',
      dataIndex: 'serviceCode',
      key: 'serviceCode',
    },
    {
      title: 'Наименование государственных услуг',
      dataIndex: 'nameOfService',
      key: 'nameOfService',
      width: 200,
    },
    {
      title: 'Количество обоснованных',
      children: [
        {
          title: 'Выданных в электроннном виде',
          children: [
            {
              title: 'физ. лица',
              dataIndex: 'justifiedElectronicFiz',
              key: 'justifiedElectronicFiz',
              align: 'center',
              className: 'fizColumn',
              render: (_, record) => record.justifiedElectronicFiz || 0
            },
            {
              title: 'юр. лица',
              dataIndex: 'justifiedElectronicYr',
              key: 'justifiedElectronicYr',
              align: 'center',
              className: 'yrColumn',
              render: (_, record) => record.justifiedElectronicYr || 0
            },
          ],
        },
        {
          title: 'Выданных в бумажном виде',
          children: [
            {
              title: 'физ. лица',
              dataIndex: 'justifiedInPaperFromFiz',
              key: 'justifiedInPaperFromFiz',
              align: 'center',
              className: 'fizColumn',
              render: (_, record) => record.justifiedInPaperFromFiz || 0
            },
            {
              title: 'юр. лица',
              dataIndex: 'justifiedInPaperFromYr',
              key: 'justifiedInPaperFromYr',
              align: 'center',
              className: 'yrColumn',
              render: (_, record) => record.justifiedInPaperFromYr || 0
            },
          ],
        },
      ],
    },
    {
      title: 'Количество необоснованных',
      children: [
        {
          title: 'Выданных в электроннном виде',
          children: [
            {
              title: 'физ. лица',
              dataIndex: 'unsubstantiatedElectronicFiz',
              key: 'unsubstantiatedElectronicFiz',
              align: 'center',
              className: 'fizColumn',
              render: (_, record) => record.unsubstantiatedElectronicFiz || 0
            },
            {
              title: 'юр. лица',
              dataIndex: 'unsubstantiatedElectronicYr',
              key: 'unsubstantiatedElectronicYr',
              align: 'center',
              className: 'yrColumn',
              render: (_, record) => record.unsubstantiatedElectronicYr || 0
            },
          ],
        },
        {
          title: 'Выданных в бумажном виде',
          children: [
            {
              title: 'физ. лица',
              dataIndex: 'unsubstantiatedInPaperFromFiz',
              key: 'unsubstantiatedInPaperFromFiz',
              align: 'center',
              className: 'fizColumn',
              render: (_, record) => record.unsubstantiatedInPaperFromFiz || 0
            },
            {
              title: 'юр. лица',
              dataIndex: 'unsubstantiatedInPaperFromYr',
              key: 'unsubstantiatedInPaperFromYr',
              align: 'center',
              className: 'yrColumn',
              render: (_, record) => record.unsubstantiatedInPaperFromYr || 0
            },
          ],
        },
      ],
    },
  ],
  3: [
    {
      title: '№ п/п',
      dataIndex: 'number',
      key: 'number',
      width: 110,
    },
    {
      title: 'Выполняемые мероприятия',
      dataIndex: 'subcategory',
      key: 'subcategory',
    },
    {
      title: 'Количество',
      dataIndex: 'count',
      key: 'count',
      align: 'center',
      width: 110,
    },
  ],
};


export const LEGAL_ENTITIES = [
  '203006',
  '203006/1',
  '203006/2',
  '404001',
  '701001',
  '701003',
  '701004',
  '704002',
  '704004',
  '704005',
  '704005/1',
  '704005/2',
  '704005/3',
  '704008',
  '705002',
  '705003',
  '705004',
  '705005',
  '705006',
  '705007',
  '705008',
  '705009',
  '706002',
  '706004',
  '2701001',
  '201004',
  '201004/1',
  '201004/2',
  '201005',
  '201005/1',
  '201005/2',
  '201006',
  '201006/1',
  '201006/2',
  '201007',
  '201007/1',
  '201007/2',
  '201007/3',
  '201007/4',
  '201007/5',
  '201007/6',
  '201007/7',
  '201007/8',
  '201007/9',
  '201007/10',
  '201007/11',
  '201013',
  '201013/1',
  '201013/2',
  '401001',
  '401001/1',
  '401001/2',
  '401001/3',
  '401002',
  '401002/1',
  '401002/2',
  '402001',
  '402001/1',
  '402001/2',
  '2501006',
  '2501006/1',
  '2501006/2',
  '2501006/3',
  '2501006/4',
  '2501006/5',
  '2501006/6',
  '2501006/7',
  '2501006/8',
  '2501006/9',
  '2501006/10',
  '2501006/11',
  '2501006/12',
  '2501006/13',
  '2501009',
  '102002',
  '102002/1',
  '102002/2',
  '102002/3',
  '102002/4',
  '2701002',
  '2701003',
  '2701005',
  '704006',
  '704006/1',
  '704006/2',
  '704006/3',
  '201014',
  '201014/1',
  '201014/2',
  '201014/3',
  '201014/4',
  '201014/5',
  '201014/6',
  '201014/7',
  '201014/8',
  '201014/9',
  '201014/10',
  '201014/11',
  '201014/12',
  '201014/13',
  '201015',
  '201015/1',
  '201015/2',
  '201015/3',
  '201015/4',
  '201015/5',
  '201015/6',
  '201015/7',
  '402002',
  '402003',
  '402004',
  '402006',
  '402007',
  '402010',
  '402013',
  '403001',
  '403002',
  '403003',
  '403004',
  '403005',
  '403005/1',
  '403005/2',
  '403006',
  '403007',
  '403008',
  '403009',
  '403010',
  '403011',
  '403012',
  '403015',
  '404002',
  '404003',
  '404004',
  '404006',
  '404007',
  '803002',
  '803005',
  '803006',
  '803007-1',
  '803008',
  '803009',
  '803013',
  '803013/1',
  '803013/2',
  '803014',
  '803016',
  '801012',
  '403013',
  '403013/1',
  '403013/2',
  '403013/3',
  '403014',
  '803005',
  '803007-1',
  '803014',
  '2501005',
  '2501005/1',
  '2501005/2',
  '2501005/3',
  '2501005/4',
  '2501005/5',
  '2501005/6',
  '2501005/7',
  '2501005/8',
  '2501005/9',
  '2501005/10',
  '2501009',
  '201011',
  '201011/1',
  '201011/2',
  '201011/3',
  '201011/4',
  '201011/5',
  '701002',
  '701002/1',
  '701002/2',
  '2302002',
];

export const LISTS_SERVICE_PROVIDER_FOR_EXPORT = {
  'Акимат города Кокшетау':	'Кокшетау',
  'Акимат города Степногорск':	'Степногорск',
  'Акимат Аккольского района':	'Акколь',
  'Акимат Аршалынского района':	'Аршалы',
  'Акимат Астраханского района':	'Астрахань',
  'Акимат Атбасарского района':	'Атбасар',
  'Акимат района Биржан сал': 'Биржан сал',
  'Акимат Есильского района':	'Есиль',
  'Акимат Жаксынского района':	'Жаксы',
  'Акимат Жаркаинского района':	'Жаркаин',
  'Акимат Зерендинского района':	'Зеренда',
  'Акимат Коргалжынского района':	'Коргалжын',
  'Акимат Сандыктауского района':	'Сандыктау',
  'Акимат Целиноградского района':	'Целиноград',
  'Акимат Шортандинского района':	'Шортанды',
  'Акимат Бурабайского района':	'Бурабай',
  'Акимат Егиндыкольского района':	'Егиндыколь',
  'Акимат Буландынского района':	'Буланды',
  'Акимат Ерейментауского района':	'Ерейментау',
  'Акимат города Косшы':	'Косшы',
  'Управление ветеринарии Акмолинской области':	'ВЕТЕРИНАР',
  'Управление сельского хозяйства и земельных отношений Акмолинской области':	'СЕЛЬХОЗ',
  'Управление координации занятости и социальных программ Акмолинской области':	'ЗАНЯТОСТЬ',
  'Управление образования Акмолинской области':	'ОБРАЗОВАНИЕ',
  'Управление туризма Акмолинской области': 'ТУРИЗМ',
  'Управления предпринимательства и промышленности Акмолинской области': 'ПРЕДПРИНИМАТЕЛЬСТВО',
  'Управление природных ресурсов и регулирования природопользования Акмолинской области':	'ПРИРОДНЫЕ РЕСУРСЫ',
  'Управление пассажирского транспорта и автомобильных дорог Акмолинской области': 'ПАССАЖИРСКИЙ ТРАНСПОРТ',
  'Управление культуры Акмолинской области': 'КУЛЬТУРА',
  'Управление по делам религии Акмолинской области': 'РЕЛИГИЯ',
  'Управление строительства, архитектуры и градостроительства Акмолинской области':'АРХИТЕКТУРА',
  'Управление здравоохранения Акмолинской области':'ЗДРАВООХРАНЕНИЕ',
  'Управление государственного архитектурно-строительного контроля Акмолинской области':'ГАСК',
  'Управление цифровизации и архивов Акмолинской области':'ЦИФРОВИЗАЦИЯ',
  'Управление физической культуры и спорта Акмолинской области':'СПОРТ',
};