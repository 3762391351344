import { sumBy, chain, isArray } from 'lodash';
import { EDITABLE_COLUMNS } from '../components/tabs/ChartBuilder/constants';

export const TOTAL_SERVICE_CODE = '0000000';

export const combineSum = (data, type, returnTotalObj = false) => {
  const totalObj = { nameOfService: 'Итого' };

  EDITABLE_COLUMNS[type].forEach((el) => {
    totalObj[el] = sumBy(data, (o) => o[el]);
  });
  if (returnTotalObj) {
    totalObj.summaryFiz = sumBy(data, (o) => o.summaryFiz);
    totalObj.summaryYr = sumBy(data, (o) => o.summaryYr);
    return totalObj;
  }
  return data.concat(totalObj);
};

export const combineWithNumbers = (data, parentNumber = '') => {
  return data.map((obj, index) => {
    const number = `${parentNumber && parentNumber + '.'}${index + 1}`;
    if (isArray(obj.children)) {
      return {
        ...obj,
        key: number,
        number: number,
        children: combineWithNumbers(obj.children, number),
      };
    } else return { ...obj, key: number, number: number, count: obj.count || 0 };
  });
};

export const getGroupedData = (data, groupType) =>
  chain(data)
    .groupBy(groupType)
    .map((value, key) => {
      if (groupType === 'category') {
        return ({
          count: value.reduce((sum, cur) => sum += cur.count, 0),
          type: groupType,
          subcategory: key,
          subcategoryId: value[0].subcategoryId,
          children: value.filter((item) => item.subcategory).length ? value : null,
        });
      }
      if (groupType === 'subcategory') {
        return ({
          count: value.reduce((sum, cur) => sum += cur.count, 0),
          type: groupType,
          subcategory: key,
          subcategoryId: value[0].subcategoryId,
        });
      }
      return ({
        count: value.reduce((sum, cur) => sum += cur.count, 0),
        type: groupType,
        subcategory: key,
        children: value,
      });
    }
    )
    .value();

export const groupData = (data) => {
  const groupedChapter = getGroupedData(
    data.filter((item) => item.nameOfService !== 'Итого'),
    'chapter'
  );
  const groupedCategory = groupedChapter.map((chapter) => {
    return {
      ...chapter,
      children: getGroupedData(chapter.children, 'category'),
    };
  });

  const groupedSubcategory = groupedCategory.map((chapter) => ({
    ...chapter,
    children: chapter.children.map((category) => ({
      ...category,
      children: category.children ? getGroupedData(category.children, 'subcategory') : null,
    }))
  }));

  return combineWithNumbers(groupedSubcategory);
};
