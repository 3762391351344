import React from 'react';
import { Button, Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from '../../../ChartBuilder/ChartBuilder.module.scss';
import { groupData } from '../../../../../utils';
import { summaryTable } from '../../../../../utils/SummaryTable';
import { useEditChart } from './hooks/useEditChart';

export const EditChart = ({ reports, setReports, formValues, handleCancelModal }) => {
  const { t } = useTranslation();
  const { isLoading, columns, handleClickSave } = useEditChart({
    formValues,
    reports,
    setReports,
    handleCancelModal,
  });

  return (
    <div>
      <Spin tip={t('intersections.loadingPleaseWait') + '...'} spinning={isLoading}>
        <Table
          rowKey={(record) => (formValues.chartType === 3 ? record.key : record.serviceCode)}
          columns={columns}
          dataSource={formValues.chartType === 3 ? groupData(reports) : reports}
          bordered
          scroll={{
            x: formValues.chartType === 1 ? 1700 : null,
            y: window.innerHeight - 100,
          }}
          className={`table-${formValues.chartType} ${styles.table}`}
          summary={formValues.chartType === 3 ? summaryTable(reports) : null}
        />
        <Button type="primary" onClick={handleClickSave} disabled={!formValues.chartType}>
          Сохранить
        </Button>
      </Spin>
    </div>
  );
};
