import React, { Component } from 'react';
import BasicLayout from '../components/layout/BasicLayout';
import { API_AUTH } from '../config';
import {
  Table,
  Modal,
  message,
  Divider,
  Popconfirm,
  Button,
  Input,
  Select,
  Tabs,
} from 'antd';
import { debounce } from 'lodash';
import { withTranslation } from 'react-i18next';
import { getNetwork } from '../utils/getNetwork';
class RolesPage extends Component {
  constructor() {
    super();
    this.state = {
      rolesMain: [],
      roles: [],
      cases: [],
      defaultCases: [],
      showModal: false,
      modalInputValue: '',
      selectedRow: {},
      menuCurrent: 'updateCase',
      checkedTabs: [],
      checkedSubTabs: [],
      tabsOptionList: [],
      subTabsOptionList: [],
      roleChange: [],
      caseChange: [],
      showAdd: false,
      showAddModal: false,
      showAddUpdateModal: false,
      code: '',
      description: '',
      codeCase: '',
      descriptionCase: '',
    };
    this.getData = this.getData.bind(this);
    this.modalHandleOk = this.modalHandleOk.bind(this);
    this.modalHandleCancel = this.modalHandleCancel.bind(this);
    this.modalChangeRoleHandleOk = this.modalChangeRoleHandleOk.bind(this);
    this.modalAddingHandleCancel = this.modalAddingHandleCancel.bind(this);
    this.modalCaseHandleCancel = this.modalCaseHandleCancel.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openAddUpdateCaseModal = this.openAddUpdateCaseModal.bind(this);
    this.handleChangeCase = this.handleChangeCase.bind(this);
    this.handleOpenChange = this.handleOpenChange.bind(this);
    this.handleChangeRole = this.handleChangeRole.bind(this);
    this.changeRoleInputValue = this.changeRoleInputValue.bind(this);
    this.changeCaseInputValue = this.changeCaseInputValue.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.checkCaseInput = this.checkCaseInput.bind(this);
    this.checkAddRoleInput = this.checkAddRoleInput.bind(this);
    this.callback = this.callback.bind(this);
  }

  componentDidMount() {
    this.getData();
    // this.parseTabs();
  }

  getData() {
    getNetwork().get(API_AUTH + '/get/roles').then((response) => {
      let rolesMain = response.data;
      this.setState({ rolesMain });
    });
    getNetwork().get(API_AUTH + '/get/roleCases').then((response) => {
      let roles = response.data;
      this.setState({ roles });
    });
    getNetwork().get(API_AUTH + '/get/cases').then((response) => {
      let casesResponse = response.data;
      this.setState({
        cases: casesResponse,
      });
    });
  }

  confirmDelete(value) {
    let { t } = this.props;
    const { rolesMain } = this.state;
    let payloadRole;

    rolesMain.map((e) => {
      if (e.code == value.code) {
        payloadRole = e;
      }
    });

    getNetwork()
      .post(API_AUTH + '/delete/roleCases', JSON.stringify(payloadRole), {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        message.success(t('settingUpRoles.savedBy'));
        this.getData();
      });
    getNetwork().post(API_AUTH + '/delete/role', JSON.stringify(payloadRole), {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  fetchData = debounce(() => {
    let { t } = this.props;
    this.getData();
    message.success(t('settingUpRoles.savedBy'));
  }, 500);

  modalHandleOk() {
    let { t } = this.props;
    let { menuCurrent, caseChange } = this.state;
    if (menuCurrent === 'updateCase') {
      getNetwork()
        .put(API_AUTH + '/edit/case', JSON.stringify(caseChange), {
          headers: { 'Content-Type': 'application/json' },
        })
        .then(() => {
          message.success(t('settingUpRoles.savedBy'));
          this.getData();
        });
    }
    if (menuCurrent === 'deleteCase') {
      getNetwork()
        .post(API_AUTH + '/delete/case', JSON.stringify(caseChange), {
          headers: { 'Content-Type': 'application/json' },
        })
        .then(() => {
          message.success(t('settingUpRoles.savedBy'));
          this.getData();
        });
    }
    if (menuCurrent === 'addCase') {
      getNetwork()
        .post(API_AUTH + '/add/case', JSON.stringify(caseChange), {
          headers: { 'Content-Type': 'application/json' },
        })
        .then(() => {
          message.success(t('settingUpRoles.savedBy'));
          this.getData();
        });
    }
  }

  checkAddRoleInput() {
    let { t } = this.props;
    let { code, description, roles } = this.state;
    code = code.trim();
    if (code && description) {
      let bool = !roles.some((role) => role.code.trim() === code);

      if (bool) {
        this.addRole();
      } else {
        message.warning(
          t('settingUpRoles.thisUserAlreadyExistsPleaseEnterAnother')
        );
      }
    } else {
      message.error(t('settingUpRoles.pleaseFillInAllFields'));
    }
  }
  modalChangeRoleHandleOk() {
    let { t } = this.props;
    const { roleChange, roles, rolesMain } = this.state;
    let payload;
    let payloadRole;
    roles.map((e) => {
      if (e.code == this.state.selected.code) {
        payload = e;
      }
    });

    rolesMain.map((e) => {
      if (e.code == this.state.selected.code) {
        payloadRole = e;
      }
    });

    if (roleChange.code) payload.code = roleChange.code;
    if (roleChange.code) payloadRole.code = roleChange.code;
    if (roleChange.description) payload.description = roleChange.description;
    if (roleChange.description)
      payloadRole.description = roleChange.description;
    if (roleChange.cases) payload.cases = roleChange.cases;

    getNetwork().post(API_AUTH + '/delete/roleCases', JSON.stringify(payloadRole), {
      headers: { 'Content-Type': 'application/json' },
    });

    payload.cases.map((e) => {
      let payloadRoleCases = { role_id: payloadRole.id, case_id: e.id };
      getNetwork().post(
        API_AUTH + '/add/casesroles',
        JSON.stringify(payloadRoleCases),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
    });

    getNetwork()
      .put(API_AUTH + '/edit/role', JSON.stringify(payloadRole), {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        message.success(t('settingUpRoles.savedBy'));
        this.getData();
      });
  }

  changeRoleInputValue(e, key) {
    const role = { ...this.state.roleChange, [key]: e.target.value };
    this.setState({
      roleChange: role,
    });
  }

  checkCaseInput() {
    let { t } = this.props;
    let { code, description, cases, caseChange } = this.state;
    code = code.trim();
    if ((code && description) || (caseChange.code && caseChange.description)) {
      let bool = !cases.some((cas) => cas.code.trim() === code);
      if (bool) {
        this.modalHandleOk();
      } else {
        message.warning(
          t('settingUpRoles.thisUserAlreadyExistsPleaseEnterAnother')
        );
      }
    } else {
      message.error(t('settingUpRoles.pleaseFillInAllFields'));
    }
  }
  changeCaseInputValue(e, key) {
    const cas = { ...this.state.caseChange, [key]: e.target.value };
    this.setState({
      caseChange: cas,
    });
    // this.handleTableUser();
  }

  changeInputValue(e, key) {
    this.setState({
      [key]: e.target.value,
    });
  }
  modalHandleCancel() {
    this.setState({
      showModal: false,
    });
  }
  modalAddingHandleCancel() {
    this.setState({
      showAddModal: false,
    });
  }
  modalCaseHandleCancel() {
    this.setState({
      showAddUpdateModal: false,
    });
  }

  callback(e) {
    this.setState({
      menuCurrent: e,
      caseChange: { code: '', description: '' },
    });
  }

  handleOpenChange(val) {
    this.setState({ showAdd: val });
  }
  handleChangeRole(value) {
    let casesArray = [];
    this.state.cases.map((e) => {
      if (value.includes(e.description)) {
        casesArray.push(e);
      }
    });
    const cases = { ...this.state.roleChange, cases: casesArray };
    this.setState({
      roleChange: cases,
    });
  }
  handleChangeCase(value) {
    let cas;
    this.state.cases.map((e) => {
      if (value === e.description) {
        cas = e;
      }
    });
    this.setState({
      caseChange: cas,
    });
  }

  addRole() {
    let { t } = this.props;
    let payload = {
      code: this.state.code,
      description: this.state.description,
    };
    let header = { headers: { 'Content-Type': 'application/json' } };
    getNetwork()
      .post(API_AUTH + '/add/role', JSON.stringify(payload), header)
      .then(() => {
        message.success(t('settingUpRoles.addedBy'));
        this.getData();
        this.setState({ showAdd: false });
      });
  }

  openModal(record) {
    this.setState({
      showModal: true,
      selected: record,
      roleChange: { code: record.code, description: record.description },
    });
  }

  openAddModal() {
    this.setState({
      showAddModal: true,
      roleChange: { code: '', description: '' },
    });
  }

  openAddUpdateCaseModal() {
    this.setState({
      showAddUpdateModal: true,
    });
  }

  render() {
    let { t } = this.props;
    const columns = [
      {
        title: t('settingUpRoles.code'),
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: t('settingUpRoles.description'),
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: t('settingUpRoles.case'),
        dataIndex: 'cases',
        key: 'cases',
        render: (text, record) => {
          return (
            <span>{record.cases.map((e) => e.description).join(', ')}</span>
          );
        },
      },
      {
        title: t('settingUpRoles.action'),
        key: 'action',
        render: (text, record) => (
          <span>
            <span className="link" onClick={() => this.openModal(record)}>
              {t('settingUpRoles.edit')}
            </span>
            <Divider type="vertical" />
            <Popconfirm
              title="Точно хотите удалить?"
              onConfirm={() => this.confirmDelete(record)}
              okText="Да"
              cancelText="Нет"
            >
              <a href="#"> {t('settingUpRoles.delete')}</a>
            </Popconfirm>
          </span>
        ),
      },
    ];
    const config = {
      pagination: {
        pageSizeOptions: ['10', '20', '30', '60', '80', '100'],
        showSizeChanger: true,
        defaultPageSize: 30,
      },
    };

    return (
      <BasicLayout>
        <h3> {t('settingUpRoles.allRoles')}</h3>
        <Button
          type="primary"
          style={{ float: 'right', marginBottom: '20px', zIndex: 12 }}
          onClick={() => this.openAddModal()}
        >
          {t('settingUpRoles.addRole')}
        </Button>
        <Button
          type="primary"
          style={{
            float: 'right',
            marginBottom: '20px',
            zIndex: 12,
            marginRight: '5px',
          }}
          onClick={() => this.openAddUpdateCaseModal()}
        >
          {t('settingUpRoles.add') +
            '/' +
            t('settingUpRoles.delete') +
            '/' +
            t('settingUpRoles.edit') +
            ' ' +
            t('settingUpRoles.case')}
        </Button>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={this.state.roles}
          {...config}
        />
        <Modal
          title={t('settingUpRoles.changingRoles')}
          visible={this.state.showModal}
          onOk={this.modalChangeRoleHandleOk}
          onCancel={this.modalHandleCancel}
        >
          <div>
            <div style={{ marginBottom: '10px' }}>
              <Input
                placeholder={t('settingUpRoles.code')}
                value={this.state.roleChange.code}
                onChange={(e) => this.changeRoleInputValue(e, 'code')}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Input
                placeholder={t('settingUpRoles.description')}
                value={this.state.roleChange.description}
                onChange={(e) => this.changeRoleInputValue(e, 'description')}
              />
            </div>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder={t('settingUpRoles.case')}
              key="description"
              onChange={this.handleChangeRole}
              defaultValue={[]}
            >
              {this.state.cases.map((e) => (
                <Select.Option key={e.id} value={e.description}>
                  {e.description}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Modal>
        <Modal
          title={t('settingUpRoles.case')}
          visible={this.state.showAddUpdateModal}
          onOk={this.checkCaseInput}
          onCancel={this.modalCaseHandleCancel}
        >
          <div>
            <Tabs defaultActiveKey="updateCase" onChange={this.callback}>
              <Tabs.TabPane tab={t('settingUpRoles.edit')} key="updateCase">
                <div style={{ marginBottom: '10px' }}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('settingUpRoles.case')}
                    key="description"
                    onChange={this.handleChangeCase}
                  // defaultValue={this.state.defaultCases}
                  >
                    {this.state.cases.map((e) => (
                      <Select.Option key={e.id} value={e.description}>
                        {e.description}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div style={{ marginBottom: '10px' }}>
                  <Input
                    placeholder={t('settingUpRoles.code')}
                    value={this.state.caseChange.code}
                    onChange={(e) => this.changeCaseInputValue(e, 'code')}
                  />
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <Input
                    placeholder={t('settingUpRoles.description')}
                    value={this.state.caseChange.description}
                    onChange={(e) =>
                      this.changeCaseInputValue(e, 'description')
                    }
                  />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t('settingUpRoles.add')} key="addCase">
                <div style={{ marginBottom: '10px' }}>
                  <Input
                    placeholder={t('settingUpRoles.code')}
                    value={this.state.caseChange.code}
                    onChange={(e) => this.changeCaseInputValue(e, 'code')}
                  />
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <Input
                    placeholder={t('settingUpRoles.description')}
                    value={this.state.caseChange.description}
                    onChange={(e) =>
                      this.changeCaseInputValue(e, 'description')
                    }
                  />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t('settingUpRoles.delete')} key="deleteCase">
                <div style={{ marginBottom: '10px' }}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('settingUpRoles.case')}
                    key="description"
                    onChange={this.handleChangeCase}
                  // defaultValue={this.state.defaultCases}
                  >
                    {this.state.cases.map((e) => (
                      <Select.Option key={e.id} value={e.description}>
                        {e.description}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Modal>
        <Modal
          title={t('settingUpRoles.addRole')}
          visible={this.state.showAddModal}
          onOk={this.checkAddRoleInput}
          onCancel={this.modalAddingHandleCancel}
        >
          <div>
            <div style={{ marginBottom: '10px' }}>
              <Input
                placeholder={t('settingUpRoles.code')}
                value={this.state.code}
                onChange={(e) => this.changeInputValue(e, 'code')}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Input
                placeholder={t('settingUpRoles.description')}
                value={this.state.description}
                onChange={(e) => this.changeInputValue(e, 'description')}
              />
            </div>
          </div>
        </Modal>
      </BasicLayout>
    );
  }
}
export default withTranslation()(RolesPage);
