import { sortBy } from 'lodash';

export let arrCategoryNumber = [];
export let arrChapterNumber = [];

const toFlatList = (data = []) => {
  return data.reduce((arr, el) => {
    if (Array.isArray(el.children)) {
      return [...arr, el, ...toFlatList(el.children)];
    } else {
      return [...arr, el];
    }
  }, []);
};

const getChartType1Data = (el, index) => [
  index + 1,
  el.serviceCode,
  el.nameOfService,
  el.summaryFiz || 0,
  el.summaryYr || 0,
  el.serviceCountByStateorgPeopleFiz || 0,
  el.serviceCountByStateorgPeopleYr || 0,
  el.serviceCountByStateorgCompaniesFiz || 0,
  el.serviceCountByStateorgCompaniesYr || 0,
  el.inPeperFormFiz || 0,
  el.inPeperFormYr || 0,
  el.electronicalyThroughtInformationSystemsFiz || 0,
  el.electronicalyThroughtInformationSystemsYr || 0,
  el.throughTheServiceProviderFiz || 0,
  el.throughTheServiceProviderYr || 0,
];

const getChartType2Data = (el, index) => [
  index + 1,
  el.serviceCode,
  el.nameOfService,
  el.justifiedElectronicFiz || 0,
  el.justifiedElectronicYr || 0,
  el.justifiedInPaperFromFiz || 0,
  el.justifiedInPaperFromYr || 0,
  el.unsubstantiatedElectronicFiz || 0,
  el.unsubstantiatedElectronicYr || 0,
  el.unsubstantiatedInPaperFromFiz || 0,
  el.unsubstantiatedInPaperFromYr || 0,
];

export const combineDataToXls = (data, chartType) => {
  switch (chartType) {
    case 1:
      return data.map(getChartType1Data);
    case 2:
      return data.map(getChartType2Data);
    case 3:
      arrCategoryNumber = [];
      arrChapterNumber = [];
      return sortBy(toFlatList(data), [(o) => parseFloat(o.number)]).map((el) => {
        if (el.type === 'category') arrCategoryNumber.push(el.number);
        if (el.type === 'chapter') arrChapterNumber.push(el.number);
        return [el.number, el.subcategory, el.count];
      });
    default:
      return [];
  }
};