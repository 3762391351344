import React, { Component } from 'react';
import BasicLayout from '../../components/layout/BasicLayout';
import { withTranslation } from 'react-i18next';
import styles from './Error.module.scss';

class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { t } = this.props;
    return (
      <BasicLayout>
        <div className={styles.title}t>
          <h1>{t('error.doNotHaveAccessToViewThisModule')}</h1>
        </div>
      </BasicLayout>
    );
  }
}

export default withTranslation()(Error);
