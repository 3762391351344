import { useCallback, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { chain } from 'lodash';
import { getNetwork } from '../../../../../../../../utils/getNetwork';
import { API_ROOT } from '../../../../../../../../config';
import { EDITABLE_COLUMNS } from '../../../../ChartBuilder/constants';
import { EditableColumns } from '../EditableColumns';

export const useEditChart = ({ formValues, reports, setReports, handleCancelModal }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const getSumValue = useCallback(
    (elem, name) =>
      EDITABLE_COLUMNS[formValues.chartType].reduce((acc, cur) => {
        if (cur.includes(name)) {
          acc += elem[cur] || 0;
        }
        return acc;
      }, 0),
    [formValues.chartType]
  );

  const updateData = useCallback(
    (elem) => {
      return {
        ...elem,
        summaryFiz: getSumValue(elem, 'Fiz'),
        summaryYr: getSumValue(elem, 'Yr'),
      };
    },
    [getSumValue]
  );

  const checkModifyElement = useCallback(
    (name, isEdit) => {
      const fieldsYr = EDITABLE_COLUMNS[formValues.chartType].filter((title) =>
        title.includes('Yr')
      );
      if (fieldsYr.includes(name) && !isEdit) {
        return true;
      }
      return false;
    },
    [formValues.chartType]
  );

  const getTotalField = useCallback(
    (name) =>
      reports.reduce((acc, cur) => {
        if (cur[name] && cur.nameOfService !== 'Итого') {
          acc += cur[name];
        }
        return acc;
      }, 0),
    [reports]
  );

  const { columns, errors } = EditableColumns({
    formValues,
    getTotalField,
    checkModifyElement,
    setReports,
    updateData,
  });

  const requestEditReport = async (body) => {
    const result = await getNetwork().put(`${API_ROOT}/myReport/report${formValues.chartType}`, body);

    if (result.status === 200) {
      setIsLoading(false);

      localStorage.removeItem('formReport');
      localStorage.removeItem('reportsStash');
      localStorage.removeItem('formCreateReport');
      handleCancelModal();

      message.success(t('gosServiceList.successSavingReport'));
    } else {
      message.error(t('error.errorSavingReport'));
    }
  };

  const getCountValue = (arr, name, elem) => {
    const result = arr.find((value) => value[name] === elem[name]);
    if (result && result.value) {
      return result.value;
    }
    return null;
  };

  const handleClickSave = async () => {
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      message.error(t('error.pleaseFillInTheHighlightedFields'));
      return;
    }
    const chapterGroups = chain(reports)
      .groupBy('chapterId')
      .map((value) => value)
      .value();

    const chapterResult = chapterGroups.reduce((acc, arr) => {
      if (arr[0].subcategoryId) {
        const sum = arr.reduce((sum, cur) => sum + cur.count, 0);
        acc.push({
          chapterId: arr[0].chapterId,
          value: sum,
        });
      }
      return acc;
    }, []);

    const categoryGroups = chain(reports)
      .groupBy('categoryId')
      .map((value) => value)
      .value();
    const categoryResult = categoryGroups.reduce((acc, arr) => {
      if (arr[0].subcategoryId) {
        const sum = arr.reduce((sum, cur) => (sum += cur.count), 0);
        acc.push({
          categoryId: arr[0].categoryId,
          value: sum,
        });
      }
      return acc;
    }, []);

    const newData = reports.reduce((acc, cur) => {
      if (cur.nameOfService === 'Итого') {
        return acc;
      }

      const result =
        formValues.chartType === 3
          ? {
            id: cur.id,
            year: cur.year,
            month: cur.month,
            subcategoryId: cur.subcategoryId,
            categoryId: cur.categoryId,
            chapterId: cur.chapterId,
            organizationsOfAkmolaRegionId: formValues.org,
            monthOfFillingId: formValues.month,
            yearOfFillingId: formValues.year,
            count: cur.count,
            categoryCount: getCountValue(categoryResult, 'categoryId', cur),
            chapterCount: getCountValue(chapterResult, 'chapterId', cur),
          }
          : {
            ...cur,
            organizationsOfAkmolaRegionId: formValues.org,
            yearOfFillingId: formValues.year,
            monthOfFillingId: formValues.month,
          };

      return acc.concat(result);
    }, []);

    requestEditReport(newData);
  };
  return { isLoading, columns, handleClickSave };
};
