import React, { Component } from 'react';
import { Button, Layout, Menu, message } from 'antd';
import { API_AUTH } from '../../config';
import { withTranslation } from 'react-i18next';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { getNetwork } from '../../utils/getNetwork';
const { Header } = Layout;

class HeaderComponent extends Component {
  constructor() {
    super();

    this.state = {
      user: {},
    };
  }

  componentDidMount() {
    const userAsJSON = localStorage.getItem('ccAdmin.currentUser');
    const currentUser = JSON.parse(userAsJSON);
    this.setState({ user: currentUser });
  }

  logOut() {
    getNetwork().get(`${API_AUTH}/logout`).then(() => {
      message.success('Вы вышли с аккаунта');
      localStorage.removeItem('ccAdmin.currentUser');
      localStorage.removeItem('modules');
      window.location.href = '/#/login';
    });
    localStorage.removeItem('formReport');
    localStorage.removeItem('reportsStash');
    localStorage.removeItem('formCreateReport');
    localStorage.removeItem('initialReports');
  }

  render() {
    const { t, i18n, collapsed, handleCollapseMenu } = this.props;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
    return (
      <Header className="header">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <Button
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              size={20}
              onClick={handleCollapseMenu}
            />
            <div className="logo">
              <h3 style={{ margin: 0 }} className="color-white">{t('main.SCcontrolPanel')}</h3>
            </div>
          </div>

          <Menu
            theme="dark"
            mode="horizontal"
            style={{ minWidth: '18%' }}
          >
            {this.state.user.userFIO && <Menu.Item key="2" style={{ cursor: 'initial', color: '#fff' }}>
              {this.state.user.userFIO}
            </Menu.Item>
            }
            <Menu.Item key="3" onClick={() => changeLanguage('ru')}>
              РУС
            </Menu.Item>
            <Menu.Item key="4" onClick={() => changeLanguage('kz')}>
              ҚАЗ
            </Menu.Item>
            <Menu.Item onClick={() => this.logOut()}>
              {t('main.exit')}
            </Menu.Item>
          </Menu>
        </div>
      </Header>
    );
  }
}
export default withTranslation()(HeaderComponent);
